import Button from 'components/dumb/Button'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import { useGate, useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'
import { $session } from 'models/sessions'
import {
  $staking,
  addStakeFx,
  approveStakeFx,
  WalletBalanceGate,
} from 'models/staking'
import { openWallet } from 'models/wallet'
import numeral from 'numeral'
import { useState } from 'react'
import { noop } from 'utils'
import t from 'utils/t'

export default function StakeTerminal() {
  useGate(WalletBalanceGate)

  const staking = useStore($staking)
  const session = useStore($session)

  const [input, setInput] = useState('')

  const handleInput = (value: string) => {
    setInput(value)
  }

  const onMax = () => {
    setInput(staking.walletBalance)
  }

  let buttonText = 'Stake'
  let buttonDisabled = false
  let handleClick = noop
  let inputDisabled = false
  switch (staking.stakeTerminalState(input)) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      inputDisabled = true
      break
    case 'allowanceExceeded':
      buttonText = t('approve_staking')
      handleClick = () => approveStakeFx()
      // inputDisabled = true
      break
    case 'readyToStake':
      handleClick = () => addStakeFx(input).then(() => setInput(''))
      break
    case 'waitingForInput':
      buttonText = t('enter_an_amount')
      break
    case 'insufficientBalance':
      buttonText = t('insufficient_balance')
      buttonDisabled = true
      break
    case 'notReadyToStake':
    default:
      buttonDisabled = true
      break
  }

  return (
    <div>
      <CurrencyInputPanel
        label={t('you_stake')}
        leftSub={t('current_stake')}
        leftCount={numeral(staking.stakingBalanceDec).format('0.00')}
        rightSub={`${isEmpty(session) ? '' : t('wallet')}`}
        rightCount={`${
          isEmpty(session)
            ? ''
            : numeral(staking.walletBalanceDec).format('0.00')
        }`}
        symbol={staking.stakingSymbol}
        value={input}
        onUserInput={handleInput}
        onMax={onMax}
        decimals={staking.stakingTokenDecimals}
        showMaxButton={!inputDisabled && !isEmpty(session)}
        disabled={inputDisabled}
      />
      <Button
        className="w-full"
        disabled={buttonDisabled}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </div>
  )
}

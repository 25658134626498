import Tab from 'components/dumb/StakingPageTerminalTab'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import React, { useState } from 'react'
import { ReactComponent as TerminalBlockAfter } from 'resources/images/staking/terminal-after.svg'
import { ReactComponent as TerminalBlockBefore } from 'resources/images/staking/terminal-before.svg'
import t from 'utils/t'
import ClaimTerminal from './ClaimTerminal'
import StakeTerminal from './StakeTerminal'
import UnstakeTerminal from './UnstakeTerminal'

type TabButtonType = 'stake' | 'claim' | 'unstake'

export default function SmartStakingTerminal() {
  const [currentTab, setCurrentTab] = useState<TabButtonType>('stake')
  const staking = useStore($staking)

  const handleTabClick = (tab: TabButtonType) => {
    setCurrentTab(tab)
  }

  const content = React.useMemo(() => {
    switch (currentTab) {
      case 'stake':
        return <StakeTerminal />
      case 'unstake':
        return <UnstakeTerminal />
      case 'claim':
        return <ClaimTerminal />
    }
  }, [currentTab])

  return (
    <div className="relative flex bg-graySecondary drop-shadow-blockShadow">
      <div className="flex grow flex-col p-[1.4rem]">
        <TerminalBlockBefore className="absolute right-0 top-[-0.8rem] z-0 h-[0.8rem] w-[1.6rem]" />
        <TerminalBlockAfter className="absolute left-[0] bottom-[-1.3rem] z-0 h-[1.55rem] w-[7.35rem]" />
        <div className="mb-[0.4rem] flex flex-col justify-between ml:flex-row">
          <h2 className="mb-[0.4rem] font-manrope text-[1.2rem] font-light leading-[1.3] text-blackSecondary ml:mb-0">
            {t('staking_terminal')}
          </h2>
          <div className="flex items-center space-x-2">
            <Tab
              onClick={() => handleTabClick('stake')}
              current={currentTab === 'stake'}
            >
              {t('stake')}
            </Tab>
            <Tab
              onClick={() => handleTabClick('unstake')}
              current={currentTab === 'unstake'}
            >
              {t('unstake')}
            </Tab>
            <Tab
              onClick={() => handleTabClick('claim')}
              current={currentTab === 'claim'}
            >
              {t('claim')}
            </Tab>
          </div>
        </div>
        {currentTab !== 'claim' && (
          <div className="mb-[0.8rem] w-[60%] font-manrope text-[0.8rem] leading-[1.2] text-gray dxs:mb-[1.45rem] ds:text-[0.76rem]">
            {t('staking_terminal_text', {
              stakingSymbol: staking.stakingSymbol,
              rewardSymbol: staking.rewardSymbol,
              unstakingFeeRatio: staking.unstakingFeeRatio,
            })}
          </div>
        )}

        {content}
      </div>
    </div>
  )
}

import Footer from 'components/dumb/Footer'
import SmartDashboard from 'components/smart/SmartDashboard'
import SmartHeader from 'components/smart/SmartHeader'
import SmartProjectDetails from 'components/smart/SmartProjectDetails'
import SmartHome from 'components/smart/SmartHome'
import SmartStaking from 'components/smart/SmartStaking'
import SmartTransaction from 'components/smart/SmartTransaction'
import SmartWallet from 'components/smart/SmartWallet'
import SmartRegister from 'components/smart/SmartRegister'

import React from 'react'
import { Toaster } from 'react-hot-toast'
import { RouteObject, useLocation, useRoutes } from 'react-router-dom'
import BoosterBanner from 'components/dumb/BoosterBanner'

const singlePoolMode = process.env.REACT_APP_SINGLE_POOL_ADDRESS

const routes: RouteObject[] = [
  {
    path: '/',
    element: singlePoolMode ? <SmartProjectDetails /> : <SmartHome />,
  },
  { path: '/staking', element: <SmartStaking /> },
  { path: '/dashboard', element: <SmartDashboard /> },
]
  .concat(
    singlePoolMode
      ? []
      : {
          path: '/pool/:id',
          element: <SmartProjectDetails />,
        }
  )
  .concat({
    path: '*',
    element: <div className="text-grayLight">Not found</div>,
  })

export default function App() {
  const element = useRoutes(routes)
  const location = useLocation()
  const isHome = !singlePoolMode && location.pathname === '/'

  const showBoosterBanner = process.env.REACT_APP_SHOW_BOOSTER_BANNER === 'true'

  const bg = React.useMemo(() => {
    switch (location.pathname) {
      case '/':
        return singlePoolMode ? '' : ''
      case '/staking':
        return 'bg-stakingBg bg-stakingBgT dxs:bg-stakingBgDXS dxl:bg-stakingBgDXL'
      case '/dashboard':
        return 'bg-dashboardBg bg-dashboardBgML t:bg-dashboardBgT dxs:bg-dashboardBgDXS dl:bg-dashboardBgDL dxl:bg-dashboardBgDXL'
      default:
        return ''
    }
  }, [location.pathname])

  return (
    <div
      className={`relative flex min-h-[100vh] flex-col overflow-hidden bg-no-repeat ${bg}`}
    >
      {isHome && (
        <div className="absolute left-0 h-full w-full">
          <div
            className={`
              absolute
              left-[50%] h-[24.6rem] w-[37.6rem]
              translate-x-[-50%] bg-homeShip 
              bg-contain bg-no-repeat 
              ${
                showBoosterBanner
                  ? 'top-[28rem] mm:top-[24rem] ml:top-[16rem] dxs:top-[6.5rem]'
                  : 'top-[18rem] mm:top-[17rem] ml:top-[6.5rem] dxs:top-[2.5rem]'
              }
             
              ml:left-[50%] ml:translate-x-[-10%]
               dxs:translate-x-[-15%]
            `}
          />
          <div className="h-[100vh] w-full bg-homeBg bg-cover bg-center bg-no-repeat" />
          <div className="z-2 absolute left-0 h-full w-[52px] bg-patternHomepage bg-repeat-y" />
        </div>
      )}

      <div className="absolute z-0 h-full w-full bg-homeAmbient bg-homeAmbientMM bg-no-repeat ml:bg-homeAmbientML dxs:bg-homeAmbientDXS ds:bg-contain ds:bg-top" />
      <SmartHeader routes={routes} />
      <div className="relative z-10 m-auto w-[300px] grow items-start mm:w-[90%] ml:w-[590px] t:w-[80%] dxs:w-[1000px] ds:w-[1200px]">
        {showBoosterBanner && <BoosterBanner />}
        {element}
      </div>
      <SmartWallet />
      <SmartTransaction />
      <SmartRegister />
      <Toaster position="bottom-right" reverseOrder={false} />
      <Footer />
    </div>
  )
}

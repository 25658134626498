import classNames from 'classnames'
import {
  Link,
  LinkProps,
  matchRoutes,
  RouteObject,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'

interface Props {
  to: string
  routes: RouteObject[]
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  exact?: boolean
}

export default function NavLink({
  to,
  routes,
  exact,
  className,
  activeClassName,
  inactiveClassName,
  ...rest
}: Props & LinkProps) {
  const location = useLocation()
  const resolvedLocation = useResolvedPath(to)
  const routeMatches = matchRoutes(routes, location)

  let isActive
  if (exact) {
    isActive = location.pathname === resolvedLocation.pathname
  } else {
    isActive = (routeMatches ?? []).some(
      (match) => match.pathname === resolvedLocation.pathname
    )
  }

  const allClassNames = classNames(className, {
    [activeClassName ?? '']: isActive,
    [inactiveClassName ?? '']: !isActive,
  })

  return <Link className={allClassNames} to={to} {...rest} />
}

import { SecondaryMarket } from 'gql'
import t from 'utils/t'
import Button from './Button'
import DotLeaders from './DotLeaders'

export interface Props {
  endTime: string
  totalRaised: string
  tokensSold: string
  athTokenPrice: string
  athRoi: string
  secondaryMarkets: SecondaryMarket[]
  hide?: boolean
}

export default function SaleStatistics({
  endTime,
  totalRaised,
  tokensSold,
  athTokenPrice,
  athRoi,
  secondaryMarkets,
  hide = false,
}: Props) {
  if (hide) return null

  return (
    <div className="flex flex-col bg-graySecondary p-[1rem] drop-shadow-blockShadow">
      <div className="font-bold text-blackSecondary ">
        {t('sale_has_ended')}
      </div>
      <div className="mb-[1rem] text-[0.7rem] text-grayTertiary">
        {t('successfully_funded')}
        {/* and closed on {endTime} */}
      </div>
      <div className="mb-[1rem]">
        <DotLeaders name={t('total_raised')} value={totalRaised} />
        <DotLeaders name={t('tokens_sold')} value={tokensSold} />
        <DotLeaders name={t('ath_token_price')} value={athTokenPrice} />
        <DotLeaders name={t('ath_roi')} value={athRoi} />
      </div>
      {secondaryMarkets.length > 0 && (
        <div className="mt-auto">
          {secondaryMarkets?.map((market) => (
            <Button
              key={`market-${market.url}`}
              className="mb-[0.4rem] w-full text-[0.8rem] uppercase last:mb-0"
              onClick={() => {
                window.open(market.url, '_blank', 'noopener,noreferrer')
              }}
            >
              {market.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

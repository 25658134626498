import Button from 'components/dumb/Button'
import { useStore } from 'effector-react'
import {
  $selectedPoolAddress,
  setSelectedPoolAddress,
} from 'models/generalInfo'
import { $pools } from 'models/pools'

type Props = {
  debug?: boolean
}

export default function SmartDebugButton({ debug }: Props) {
  const pools = useStore($pools)
  const poolAddress = useStore($selectedPoolAddress)

  if (!debug) {
    return null
  }

  const changePool = () => {
    const current = pools.findIndex((p) => p.address === poolAddress)
    const next = (Math.max(current, 0) + 1) % pools.length
    setSelectedPoolAddress(pools[next].address)
  }

  return (
    <Button
      className="relative font-manrope text-[0.8rem] uppercase dxs:-top-1"
      onClick={changePool}
    >
      Change pool (Debug)
    </Button>
  )
}

import Button from 'components/dumb/Button'
import { useStore } from 'effector-react'
import { $staking, getRewardFx } from 'models/staking'
import { openWallet } from 'models/wallet'
import { ReactComponent as ClaimingFishIcon } from 'resources/images/icons/claiming-fish.svg'
import { noop } from 'utils'
import { formatNumber } from 'utils/numbers'
import t from 'utils/t'

export default function ClaimTerminal() {
  const staking = useStore($staking)

  let buttonText = 'Claim'
  let buttonDisabled = false
  let handleClick = noop
  switch (staking.claimTerminalState) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      break
    case 'readyToClaim':
      handleClick = () => getRewardFx()
      break
    case 'notReadyToClaim':
    default:
      buttonDisabled = true
      break
  }
  const rewardTotal = formatNumber(staking.rewardTotalDec, {
    fractionDigits: 8,
  })

  const rewardAvailable = formatNumber(staking.rewardAvailableDec, {
    fractionDigits: 2,
  })

  return (
    <div className="relative">
      <ClaimingFishIcon className="absolute left-[5rem] top-[-1rem] w-[3rem] mm:left-[11rem] mm:top-0 mm:h-[4.7rem] mm:w-[5.3rem] ml:left-[14rem]" />
      <div className="mt-[3.35rem] text-[1.2rem] font-light dxs:mt-[3.25rem]">
        {t('available_for_claiming')}
      </div>
      <div className="flex items-baseline font-pressStart2P text-greenLightHover">
        <div className="mr-[0.5rem] text-[0.9rem] mm:text-[1.1rem] ml:mr-[0.7rem] ml:text-[1.8rem]">
          {rewardAvailable === 'NaN' ? '0' : rewardAvailable}
        </div>
        <div className="relative top-[-0.15rem] text-[0.5rem] uppercase mm:text-[0.6rem] ml:text-[0.9rem]">
          {staking.rewardSymbol}
        </div>
      </div>
      <div className="mb-[0.8rem] text-[0.7rem] text-grayTertiary">
        {t('total_earned')}: {rewardTotal}
      </div>
      <Button
        className="w-full"
        onClick={handleClick}
        // disabled
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
    </div>
  )
}

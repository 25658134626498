import { Props as TerminalProps } from 'components/collections/ProjectPageTerminalMaster'
import { Props as TierProps } from 'components/collections/ProjectPageTierMaster'
import { HomeActivePoolCardProps } from 'components/dumb/HomeActivePoolCard'
import { HomeUpcomingPoolCardProps } from 'components/dumb/HomeUpcomingPoolCard'
import { Props as HeadingProps } from 'components/dumb/ProjectPageHeading'
import { Props as SocialLinksProps } from 'components/dumb/ProjectPageSocialLinks'
import { Props as TimelineProps } from 'components/dumb/ProjectPageTimeline'
import { Props as SaleProgressProps } from 'components/dumb/SaleProgress'
import { Props as SaleStatisticsProps } from 'components/dumb/SaleStatistics'
import { ParticipationGuideProps } from 'components/smart/SmartProjectPageParticipationGuide'
import Decimal from 'decimal.js'
import { GeneralInfoQuery, GeneralInfoWithKycQuery, PoolStatus } from 'gql'
import produce from 'immer'
import find from 'lodash/find'
import numeral from 'numeral'
import dayjs, { DATE_FORMAT_SHORT, formatDate, formatUTC } from 'utils/dayjs'
import { formatNumber, formatNumberTBA, toDecimal } from 'utils/numbers'
import t from 'utils/t'

type PoolInfo = (GeneralInfoQuery | GeneralInfoWithKycQuery)['pools'][number]

export class Pool {
  original: PoolInfo
  name: string
  id: string
  address: string
  targetTokenDecimals: number
  subtitle: string
  logo: string

  constructor(pool: PoolInfo) {
    this.id = pool.id
    this.original = pool
    this.address = pool.address
    this.name = pool.meta.name
    this.logo = pool.meta.brandLogo ?? ''
    this.targetTokenDecimals = pool.targetToken?.decimals ?? 0
    this.subtitle = pool.meta.subtitle || ''
  }

  public get finished() {
    const finished =
      this.original.poolStatus === PoolStatus.Failed ||
      this.original.poolStatus === PoolStatus.SuccessfullyFinished ||
      this.original.poolStatus === PoolStatus.SoldOut

    return finished
  }

  public get inProgress() {
    return this.original.poolStatus === PoolStatus.InProgress
  }

  public get preLaunch() {
    return this.original.poolStatus === PoolStatus.Prelaunch
  }

  public get registration() {
    return this.original.poolStatus === PoolStatus.Registration
  }

  public get comingSoon() {
    return this.original.poolStatus === PoolStatus.ComingSoon
  }

  public get totalTarget() {
    return numeral(this.original.progress.totalTarget ?? 0).format(
      '0,[.][0000]'
    )
  }

  public get tokensToSell() {
    return numeral(this.original.progress.tokensToSell ?? 0).format('0,0')
  }

  public get tokenPrice() {
    return numeral(this.original.price ?? 0).format('0,[.][000000]')
  }

  public get initialMarketCap() {
    return numeral(this.original.meta.initialMarketCap ?? 0).format('0,0')
  }

  public get initialMarketCapUSD() {
    return numeral(this.original.meta.initialMarketCapUSD ?? 0).format('0,0')
  }

  public get participantsCount() {
    return `${
      this.original.statistics.participantsCount
        ? numeral(this.original.statistics.participantsCount).format('0,0')
        : '-'
    }`
  }

  public get registeredCount() {
    return `${
      this.original.statistics.registeredCount
        ? numeral(this.original.statistics.registeredCount).format('0,0')
        : '-'
    }`
  }

  public get targetTokenSymbol() {
    return this.original.targetToken?.symbol ?? ''
  }

  public get idoTokenSymbol() {
    return this.original.idoToken.symbol
  }

  public get totalRaised() {
    return `${this.original.progress.totalTarget} ${this.original.targetToken?.symbol}`
  }

  public get tokensHasBeenDistributed() {
    const currentPeriodIndex = this.original.vesting?.currentPeriodIndex

    const tokensHasBeenDistributed = this.original.vesting?.unlockPercentages
      ? this.original.vesting?.unlockPercentages.length - 1 ===
        currentPeriodIndex
      : false

    return tokensHasBeenDistributed
  }

  public get label() {
    return ''
  }

  public getHeadingProps(): HeadingProps {
    const meta = this.original.meta

    return {
      name: meta.name,
      logo: meta.brandLogo ?? '',
      subtitle: meta.subtitle ?? '',
      description: meta.description,
      incubated: !!meta.isIncubated,
      label: this.label,
      tags: meta.tags,
      dueDiligenceReport: meta.dueDiligenceReport ?? '',
      website: meta.website ?? '',

      // todo: move values to pool
      fieldsProps: {
        state: 'public',
        totalTarget: this.totalTarget,
        tokensToSell: this.tokensToSell,
        tokenPrice: this.tokenPrice,
        initialMarketCapUSD: this.initialMarketCapUSD,
        participantsCount: this.participantsCount,
        registeredCount: this.registeredCount,
        targetTokenSymbol: this.targetTokenSymbol,
        idoTokenSymbol: this.idoTokenSymbol,
        vestingUnlock: this.original.meta?.vestingDescription
          ? this.original.meta?.vestingDescription
          : 'TBA',
        // this.original.vesting?.unlockPercentages[0]
        //   ? `${this.original.vesting?.unlockPercentages[0]}% Unlock`
        //   : 'TBA',
      },
    }
  }

  public getTierProps(_connected: boolean): TierProps {
    return { hideImage: true, hide: true, state: 'notConnected' }
  }

  public getSaleProgressProps(): SaleProgressProps {
    const tokensToSell = formatNumberTBA(this.original.progress?.tokensToSell, {
      fractionDigits: 12,
    })
    const tokensSold = formatNumber(this.original.progress?.tokensSold ?? 0, {
      fractionDigits: 4,
    })
    const percent = toDecimal(this.original.progress.tokensSold)
      .mul(100)
      .div(toDecimal(this.original.progress.tokensToSell))
      .toString()

    return {
      percent,
      tokensToSell,
      tokensSold,
      symbol: this.original.idoToken.symbol,
      hide: !this.inProgress,
    }
  }

  public get athRoi() {
    // todo: catch failed pool
    const ROIAllTimeHighDec = toDecimal(this.original.meta.ROIAllTimeHigh).mul(
      100
    )
    const ROIPrefix = ROIAllTimeHighDec.gt(0) ? '+' : ''
    const ROIFormatted = formatNumberTBA(this.original.meta.ROIAllTimeHigh)
    const ROIAllTimeHigh =
      ROIFormatted !== 'TBA'
        ? `${ROIPrefix}${formatNumber(ROIAllTimeHighDec)}%`
        : 'TBA'
    return ROIAllTimeHigh
  }

  public get endTime() {
    return formatDate(this.original.pipeline?.finishTime)
  }

  public getSaleStatisticsProps(): SaleStatisticsProps {
    return {
      secondaryMarkets: this.original.meta?.secondaryMarkets ?? [],
      endTime: this.endTime,
      totalRaised: this.totalRaised,
      tokensSold: this.original.progress.tokensSold
        ? `${numeral(this.original.progress.tokensSold).format(
            '0,[.][0000]'
          )} ${this.original.idoToken.symbol}`
        : 'TBA',
      athTokenPrice: formatNumberTBA(this.original.meta?.priceAllTimeHigh),
      athRoi: this.athRoi,
      hide: !this.finished,
    }
  }

  public getTimelineProps(): TimelineProps {
    const { prelaunchTime, startTime, finishTime } = this.original.pipeline

    return {
      items: [
        {
          title: t('pre_launch'),
          date: `${formatUTC(
            prelaunchTime,
            DATE_FORMAT_SHORT,
            true
          )} - ${formatUTC(startTime, DATE_FORMAT_SHORT)}`,
          isActive: this.preLaunch,
          isLast: false,
        },
        {
          title: t('sale'),
          date: `${formatUTC(startTime, DATE_FORMAT_SHORT, true)} - ${formatUTC(
            finishTime,
            DATE_FORMAT_SHORT
          )}`,
          isActive: this.inProgress,
          isLast: false,
        },
        {
          title: t('distribution'),
          date: formatUTC(
            this.original.vesting?.unlockCheckpoints[0],
            DATE_FORMAT_SHORT
          ),
          isActive: this.finished,
          isLast: true,
        },
      ],
    }
  }

  public getSocialLinks(): SocialLinksProps {
    const links = [
      { icon: 'medium', url: this.original.meta?.medium },
      { icon: 'twitter', url: this.original.meta?.twitter },
      { icon: 'telegram', url: this.original.meta?.telegram },
      { icon: 'discord', url: this.original.meta?.discord },
      { icon: 'whitepaper', url: this.original.meta?.whitepaper },
    ]
    return { links }
  }

  public getVesting(): any {
    const vesting = this.original.vesting
    const unlockPercentages = vesting?.unlockPercentages || []
    const unlockCheckpoints = vesting?.unlockCheckpoints || []
    const claimed = this.original.account?.vesting?.claimed ?? []
    const claimTicker = this.original.idoToken.symbol

    const checkpoints = unlockCheckpoints?.map((_, i) => {
      const periodIndex = i
      const currentPeriodIndex = vesting?.currentPeriodIndex ?? -1
      const unlockPercentDecimal =
        unlockPercentages[i] ?? undefined
          ? toDecimal(unlockPercentages[i]).minus(
              toDecimal(unlockPercentages[i - 1])
            )
          : toDecimal(0)
      const unlockPercent = `${unlockPercentDecimal.toString()}%`
      const yourClaim = claimed[i]
      const yourClaimComputed = this.finished
        ? `${claimed[i] ?? '—'} ${this.original.idoToken.symbol}`
        : '—'

      const isClaimed = toDecimal(claimed[i]).gt(0)
      const unlockDate = formatUTC(unlockCheckpoints[i])
      const totalAmount = this.finished
        ? `${unlockPercentDecimal
            .div(100)
            .mul(this.original.progress?.tokensSold ?? 0)
            .toString()} ${this.original.idoToken.symbol}`
        : '—'

      let status = ''
      if (currentPeriodIndex < periodIndex) {
        status = 'Locked'
      } else if (
        currentPeriodIndex >= periodIndex &&
        !isClaimed &&
        this.finished &&
        yourClaim &&
        yourClaim !== '0'
      ) {
        status = 'Unlocked'
      } else if (isClaimed) {
        status = 'Claimed'
      } else {
        status = 'Locked'
      }

      return {
        unlockDate,
        unlockPercent,
        totalAmount,
        yourClaim,
        yourClaimComputed,
        status,
      }
    })

    return {
      checkpoints,
      claimTicker: claimTicker,
      showClaimButton: this.finished,
    }
  }

  public getTerminalProps(
    connected: boolean,
    targetTokenBalance: Decimal
  ): TerminalProps {
    const maxAllocationDec = new Decimal(
      this.original.account?.tier?.maxAllocation ?? 0
    )
    const usedAllocationDec = new Decimal(
      this.original.account?.amountPaid ?? 0
    )
    const idoTokensBoughtDec = toDecimal(this.original.account?.userToReserve)
    const idoTokenSymbol = this.original.idoToken?.symbol
    const targetTokenSymbol = this.original.targetToken?.symbol

    const usedAllocationComputed = `${formatNumber(usedAllocationDec, {
      fractionDigits: 4,
    })}/${formatNumber(maxAllocationDec, { fractionDigits: 4 })} ${
      targetTokenSymbol ?? 'USDC'
    }`
    const idoTokensBoughtComputed = `${formatNumber(idoTokensBoughtDec, {
      fractionDigits: 4,
    })} ${idoTokenSymbol}`

    const isPublic =
      this.original?.meta.private === false &&
      this.original?.meta.isKYCRequired === false

    const publicTierMaxAllocation =
      this.original?.tiers.length > 0
        ? this.original?.tiers[0].maxAllocation
        : 0

    const yourMaxAllocation = isPublic
      ? toDecimal(publicTierMaxAllocation)
      : toDecimal(
          find(this.original?.tiers, {
            level: this.original?.account?.tier?.level,
          })?.maxAllocation ?? 0
        )

    const availableAllocationDec = new Decimal(yourMaxAllocation ?? 0).minus(
      new Decimal(this.original?.account?.amountPaid ?? 0)
    )
    const availableAllocation = availableAllocationDec.toString()

    const price = this.original?.price || '0'

    const currentPeriodIndex = this.original.vesting?.currentPeriodIndex

    const vestedDec = this.original.vesting
      ? toDecimal(this.original.account?.userToReserve)
          .mul(
            typeof currentPeriodIndex === 'number'
              ? this.original.vesting.unlockPercentages[currentPeriodIndex]
              : 0
          )
          .div(100)
      : toDecimal(0)

    const claimDate =
      this.original.vesting?.unlockCheckpoints[
        typeof currentPeriodIndex === 'number' ? currentPeriodIndex : 0
      ]
    const nothingToClaim = toDecimal(
      this.original.account?.vesting?.totalClaimed
    ).eq(vestedDec)

    const allowedToClaim =
      claimDate !== '1970-01-01T00:00:00Z' &&
      dayjs(claimDate).diff(dayjs(this.original.currentTime), 's') < 0 &&
      !nothingToClaim

    const privateAndNonParticipating =
      this.original.meta.private &&
      (!this.original.account?.isRegistered ||
        this.original.account?.tier?.level === 0)

    const availableToRefundDec = toDecimal(
      this.original.account?.refundAmount
    ).eq(0)
      ? toDecimal(this.original.account?.amountPaid)
      : toDecimal(0)

    const availableToRefund = formatNumber(availableToRefundDec)

    return {
      pool: this,
      connected,
      finished: this.finished,
      poolStatus: this.original.poolStatus,
      pipeline: this.original.pipeline,
      vesting: this.original.vesting || undefined,
      vestingTotalAvailable: this.original.account?.vesting?.totalAvailable,
      idoTokensBoughtDec,
      usedAllocationComputed,
      idoTokensBoughtComputed,
      idoTokenSymbol,
      targetTokenSymbol,
      walletBalanceComputed: `${formatNumber(targetTokenBalance, {
        fractionDigits: 4,
      })} ${targetTokenSymbol}`,
      walletBalanceDec: targetTokenBalance,
      availableAllocation,
      price,
      userToReserve: formatNumber(this.original.account?.userToReserve ?? 0),
      claimed: formatNumber(this.original.account?.vesting?.totalClaimed ?? 0),
      vested: formatNumber(vestedDec),
      allowedToClaim,
      privateAndNonParticipating,
      availableToRefund,
      isPrivate: this.original.meta.private,
      isKYCRequired: this.original.meta.isKYCRequired,
      isRegistered: this.original.account?.isRegistered,
      isWhitelisted: this.original.account?.isWhitelisted,
    }
  }

  public getParticipationGuideProps(
    connected: boolean
  ): ParticipationGuideProps {
    const userParticipated = toDecimal(this.original.account?.userToReserve).gt(
      0
    )

    return {
      connected,
      isPublic: !this.original.meta.private,
      isRegistered: this.original.account?.isRegistered,
      idoTokenSymbol: this.original.idoToken.symbol,
      finished: this.finished,
      registration: this.registration,
      comingSoon: this.comingSoon,
      tokensHasBeenDistributed: this.tokensHasBeenDistributed,
      userParticipated,
    }
  }

  public getHomeActiveCardProps(): HomeActivePoolCardProps {
    const meta = this.original.meta
    return {
      address: this.original.address,
      name: meta.name,
      logo: meta.brandLogo ?? '',
      background: meta.brandBackground ?? '',
      subtitle: meta.subtitle ?? '',
      tags: meta.tags,
      idoTokenSymbol: this.idoTokenSymbol,
      targetTokenSymbol: this.targetTokenSymbol,
      totalTarget: this.totalTarget,
      tokensToSell: this.tokensToSell,
      tokenPrice: this.tokenPrice,
      initialMarketCapUSD: this.initialMarketCapUSD,
      label: this.label,
      incubated: !!meta.isIncubated,
      dueDiligenceReport: meta.dueDiligenceReport ?? '',
      inProgress: this.inProgress,
      pipeline: this.original.pipeline,
      vesting: this.original.vesting || undefined,
      poolStatus: this.original.poolStatus,
    }
  }

  public getHomeUpcomingCardProps(): HomeUpcomingPoolCardProps {
    const meta = this.original.meta
    const { links } = this.getSocialLinks()
    return {
      address: this.original.address,
      name: meta.name,
      logo: meta.brandLogo ?? '',
      background: meta.brandBackground ?? '',
      subtitle: meta.subtitle ?? '',
      tags: meta.tags,
      links,
    }
  }
}

export class PublicPool extends Pool {
  public get label() {
    return 'public'
  }

  public getHeadingProps() {
    return produce(super.getHeadingProps(), (draft) => {
      draft.label = 'public'
    })
  }

  public getTierProps(connected: boolean): TierProps {
    if (this.finished) {
      return super.getTierProps(connected)
    }

    if (!connected) {
      return {
        state: 'notConnected',
        hideImage: this.inProgress,
      }
    }

    return {
      state: 'noTierRequirements',
      hideImage: this.inProgress,
    }
  }
}

export class PrivatePool extends Pool {
  public get label() {
    return `private${this.original.meta.isKYCRequired ? ' + KYC' : ''}`
  }

  public getHeadingProps() {
    return produce(super.getHeadingProps(), (draft) => {
      draft.label = `private${this.original.meta.isKYCRequired ? ' + KYC' : ''}`
    })
  }

  public getTierProps(connected: boolean): TierProps {
    if (this.finished) {
      return super.getTierProps(connected)
    }

    if (!connected) {
      return {
        state: 'notConnected',
        hideImage: this.inProgress,
      }
    }

    const moreTokens = '2,500' // todo
    const betterChance = '35.3%' // todo
    const level: number = this.original.account?.tier?.level || 0

    switch (level) {
      case 5:
      case 4:
      case 3:
      case 2:
        return {
          state: `tier${level}`,
          hideImage: this.inProgress,
          moreTokens,
          betterChance,
        }
      case 1:
        return {
          state: 'tier1',
          hideImage: this.inProgress,
        }
      case 0:
      default:
        return {
          state: 'noTier',
          hideImage: this.inProgress,
        }
    }
  }

  public getTimelineProps() {
    return produce(super.getTimelineProps(), (draft) => {
      const { prelaunchTime, registrationTime } = this.original.pipeline

      draft.items.unshift({
        title: t('registration'),
        date: `${formatUTC(
          registrationTime,
          DATE_FORMAT_SHORT,
          true
        )} - ${formatUTC(prelaunchTime, DATE_FORMAT_SHORT)}`,
        isActive: this.registration,
        isLast: false,
      })
    })
  }

  public getParticipationGuideProps(
    connected: boolean
  ): ParticipationGuideProps {
    return produce(super.getParticipationGuideProps(connected), (draft) => {
      draft.isPublic = false
    })
  }
}

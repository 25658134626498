import type { Transformer } from 'unified'
import type { Parent, Node } from 'unist'
import type { Text } from 'hast'

import { h } from 'hastscript'
import { visit } from 'unist-util-visit'
import { is } from 'unist-util-is'

export type Options = Partial<OptionTypes>
interface OptionTypes {
  slugger: (s: string) => string
}

const rehypeSection =
  (options: Partial<OptionTypes>): Transformer<Parent> =>
  (tree) => {
    const slugger = options.slugger ?? defaultOptions.slugger

    const getSlug = (node: Node | null): string =>
      node == null ? '' : slugger(getText(node))

    const sections: Node[] = []
    let sectionContent: Node[] = []
    let prevHeading: Node | null = null
    for (const node of tree.children) {
      if (!is(node, { type: 'element', tagName: 'h2' })) {
        sectionContent.push(node)
        continue
      }

      sections.push(w(sectionContent, getSlug(prevHeading)))
      prevHeading = node
      sectionContent = [node]
    }
    sections.push(w(sectionContent, getSlug(prevHeading)))

    tree.children = sections
    return tree
  }

const w = (children: any[], slug: string) => h('section', { slug }, children)

const isTextNode = (node: Node): node is Text => is(node, { type: 'text' })

const getText = (tree: Node): string => {
  let text = ''
  visit(tree, (node) => {
    if (isTextNode(node)) {
      text += node.value
    }
  })

  return text
}

const defaultOptions: OptionTypes = {
  slugger: (s) => s,
}

export default rehypeSection

import { toDecimal } from 'utils/numbers'
import t from 'utils/t'

export interface Props {
  tokensSold: string
  tokensToSell: string
  symbol: string
  percent: string
  hide?: boolean
}

export default function SaleProgress({
  tokensSold,
  tokensToSell,
  symbol,
  percent,
  hide = false,
}: Props) {
  if (hide) return null

  return (
    <div className="col-span-2 bg-graySecondary p-[1.7rem] drop-shadow-blockShadow ml:col-span-1">
      <div className="relative m-auto max-h-[17rem] max-w-[17rem]">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 40 40"
          className="rotate-[-90deg] scale-110"
        >
          <circle
            className="donut-ring stroke-grayDA"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="1.5"
          ></circle>
          <circle
            className="donut-segment stroke-pink"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="1.5"
            strokeDasharray={`${percent} ${toDecimal(100)
              .minus(toDecimal(percent))
              .toString()}`}
          ></circle>
        </svg>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-pressStart2P">
          <div className=" mb-[0.3rem] text-[0.8rem]">{tokensSold}</div>
          <div className="text-[0.5rem] text-gray">
            /{tokensToSell} {symbol}
          </div>
        </div>
      </div>
      <div className="mt-[1.4rem] text-center text-[1.2rem] font-light">
        {t('sale_progress')}
      </div>
    </div>
  )
}

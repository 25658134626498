import { attach, createEvent, createStore, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { GeneralInfoQuery, GeneralInfoWithKycQuery, KycInfo } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { Kyc } from './kyc'

// hardcoded data in case backend is not accessible
// import generalInfo from './generalInfo.json'

export type GeneralInfo = GeneralInfoWithKycQuery | GeneralInfoQuery | null

export const fetchGeneralInfo = createEvent()
export const setSelectedPoolAddress = createEvent<string>()
export const setKycInfo = createEvent<KycInfo>()
//generalInfo as GeneralInfo
export const $generalInfo = createStore<GeneralInfo>(null)
export const $selectedPoolAddress = restore(setSelectedPoolAddress, '')
export const $kycInfo = restore(setKycInfo, null)
export const $kyc = $kycInfo.map((k) => new Kyc(k))

export const fetchGeneralInfoFx = attach({
  source: $session,
  async effect(session) {
    return await graphqlSdk.GeneralInfo({ session })
  },
})

export const fetchGeneralInfoWithKYCFx = attach({
  source: $session,
  async effect(session) {
    return await graphqlSdk.GeneralInfoWithKYC({ session })
  },
})

export const setResidenceConfirmed = createEvent<boolean>()
export const $residenceConfirmed = restore(setResidenceConfirmed, false)
persist({ store: $residenceConfirmed, key: 'residenceConfirmed' })

import t from 'utils/t'
import SocialLink, { SocialLinkProps } from './SocialLink'

export type Props = {
  links: SocialLinkProps[]
}

export default function ProjectPageSocialLinks({ links }: Props) {
  return (
    <div className="mb-[2.4rem] items-center justify-between border border-gray9A bg-black p-[1rem] drop-shadow-blockShadow ml:flex">
      <div className="mb-[0.6rem] text-[1.2rem] font-light text-grayLight ml:mb-0">
        {t('links')}
      </div>
      <div className="flex">
        {links?.map((link) => {
          return (
            <SocialLink
              key={`link-${link.icon}`}
              url={link.url}
              icon={link.icon}
              className="ml-[0.6rem] text-pink first:ml-0"
            />
          )
        })}
      </div>
    </div>
  )
}

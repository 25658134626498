import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import { ReactNode, useState } from 'react'
import { ReactComponent as ArrowRight } from 'resources/images/icons/arrow-right.svg'
import { ReactComponent as InfoIcon } from 'resources/images/icons/info.svg'
import { ReactComponent as RejectIcon } from 'resources/images/icons/reject.svg'
import { ReactComponent as WarningIcon } from 'resources/images/icons/warning.svg'
import { ReactComponent as SuccessIcon } from 'resources/images/icons/success.svg'
import { ReactComponent as WatchIcon } from 'resources/images/icons/watch.svg'

import { ReactComponent as TerminalBlockDecor } from 'resources/images/terminal-block-decor.svg'
import Button from '../dumb/Button'
import CurrencyInputPanel from '../dumb/CurrencyInputPanel'
import Timer from '../dumb/Timer'

import Dropdown from 'components/dumb/Dropdown'
import SmartWalletButton from 'components/smart/SmartHeader/SmartWalletButton'
import Decimal from 'decimal.js'
import { useStore } from 'effector-react'
import isEmpty from 'lodash/isEmpty'
import {
  $kyc,
  $kycInfo,
  $residenceConfirmed,
  setResidenceConfirmed,
} from 'models/generalInfo'
import { buyFx, claimFx, refundFx, setRegisterModalStatus } from 'models/pools'
import { Pool } from 'models/pools/pools'
import { $session } from 'models/sessions'
import { $staking } from 'models/staking'
import { $networkIsOk } from 'models/wallet'
import { useNavigate } from 'react-router-dom'
import { formatNumber, toDecimal } from 'utils/numbers'
import useCountdownTimeAndTitle from 'utils/pool/useCountdownTimeAndTitle'
import t from 'utils/t'

export type Props = {
  pool: Pool
  poolStatus: PoolStatus
  connected: boolean
  finished: boolean
  pipeline: PoolPipeline
  vesting?: Vesting
  vestingTotalAvailable?: string
  idoTokenSymbol?: string
  targetTokenSymbol?: string
  idoTokensBoughtDec?: Decimal
  usedAllocationComputed: string
  idoTokensBoughtComputed: string
  walletBalanceDec: Decimal
  walletBalanceComputed: string
  availableAllocation: string
  price: string
  userToReserve: string
  claimed: string
  vested: string
  allowedToClaim: boolean
  privateAndNonParticipating: boolean
  availableToRefund: string
  isPrivate: boolean
  isKYCRequired: boolean
  isRegistered?: boolean
  isWhitelisted?: boolean | null
}

export default function ProjectPageTerminalMaster({
  pool,
  poolStatus,
  connected,
  finished,
  pipeline,
  vesting,
  vestingTotalAvailable,
  usedAllocationComputed,
  idoTokensBoughtComputed,
  targetTokenSymbol,
  idoTokenSymbol,
  walletBalanceComputed,
  walletBalanceDec,
  availableAllocation,
  price,
  idoTokensBoughtDec,
  userToReserve,
  claimed,
  vested,
  allowedToClaim,
  privateAndNonParticipating,
  availableToRefund,
  isPrivate,
  isKYCRequired,
  isRegistered,
  isWhitelisted,
}: Props) {
  const residenceConfirmed = useStore($residenceConfirmed)
  const networkIsOk = useStore($networkIsOk)
  const kyc = useStore($kyc)
  const staking = useStore($staking)

  const hasTier = staking.level !== 0

  const { countdownTime, countdownTitle } = useCountdownTimeAndTitle({
    vesting,
    pipeline,
    poolStatus,
    isPrivate,
  })

  if (!connected || !networkIsOk) {
    return (
      <TerminalWrapper
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      >
        <div>
          <div className="flex flex-col items-center justify-between ml:flex-row"></div>
          <SmartWalletButton className="m-auto w-full text-center" />
        </div>
      </TerminalWrapper>
    )
  }

  if (isKYCRequired && kyc.status !== 'verified') {
    return (
      <TerminalKYC
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        kycStatus={kyc.status}
      />
    )
  }

  if (
    isPrivate &&
    !hasTier &&
    (poolStatus === PoolStatus.ComingSoon ||
      poolStatus === PoolStatus.Registration)
  ) {
    return (
      <TerminalStaking
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        stakingSymbol={staking.stakingSymbol}
      />
    )
  }

  if (isPrivate && (!hasTier || !isRegistered)) {
    return (
      <TerminalNotEligible
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        poolStatus={poolStatus}
      />
    )
  }

  if (!isKYCRequired && !residenceConfirmed && !finished) {
    return (
      <TerminalResidence
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      />
    )
  }

  if (poolStatus === PoolStatus.ComingSoon) {
    return (
      <TerminalComingSoon
        isPrivate={isPrivate}
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      />
    )
  }

  if (poolStatus === PoolStatus.Registration) {
    return (
      <TerminalRegistration
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        isRegistered={isRegistered}
      />
    )
  }

  if (poolStatus === PoolStatus.Prelaunch) {
    return (
      <TerminalPrelaunch
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        isPrivate={isPrivate}
        isWhitelisted={isWhitelisted}
      />
    )
  }

  if (poolStatus === PoolStatus.InProgress) {
    return (
      <TerminalSale
        pool={pool}
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        usedAllocationComputed={usedAllocationComputed}
        idoTokensBoughtComputed={idoTokensBoughtComputed}
        idoTokenSymbol={idoTokenSymbol}
        targetTokenSymbol={targetTokenSymbol}
        walletBalanceDec={walletBalanceDec}
        walletBalanceComputed={walletBalanceComputed}
        availableAllocation={availableAllocation}
        price={price}
      />
    )
  }

  if (
    poolStatus === PoolStatus.SuccessfullyFinished ||
    poolStatus === PoolStatus.SoldOut
  ) {
    return (
      <TerminalClaim
        pool={pool}
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
        idoTokenSymbol={idoTokenSymbol}
        idoTokensBoughtDec={idoTokensBoughtDec}
        vestingTotalAvailable={vestingTotalAvailable}
        userToReserve={userToReserve}
        claimed={claimed}
        vested={vested}
        allowedToClaim={allowedToClaim}
        privateAndNonParticipating={privateAndNonParticipating}
      />
    )
  }

  if (poolStatus === PoolStatus.Failed) {
    return <TerminalRefund pool={pool} availableToRefund={availableToRefund} />
  }

  return null
}

export type TerminalStateProps = {
  pool?: Pool | null
  countdownTime?: string | null
  countdownTitle?: string | null
  idoTokensBoughtDec?: Decimal
  usedAllocationComputed?: string
  idoTokensBoughtComputed?: string
  idoTokenSymbol?: string
  targetTokenSymbol?: string
  walletBalanceDec?: Decimal
  walletBalanceComputed?: string
  availableAllocation?: string
  price?: string
  vestingTotalAvailable?: string
  userToReserve?: string
  claimed?: string
  vested?: string
  allowedToClaim?: boolean
  privateAndNonParticipating?: boolean
  availableToRefund?: string
  isPrivate?: boolean
  kycStatus?: string
  stakingSymbol?: string
  isRegistered?: boolean
  isWhitelisted?: boolean | null
  hasTier?: boolean
  poolStatus?: string
}

function TerminalKYC({
  countdownTime,
  countdownTitle,
  kycStatus,
}: TerminalStateProps) {
  const kycInfo = useStore($kycInfo)
  const isRejectedFinal = kycStatus === 'rejectedFinal'

  const applyOptions = [
    {
      text: t('regular_kyc'),
      onClick: () => {
        window.open(kycInfo?.generalLink, '_blank', 'noopener,noreferrer')
      },
    },
    // {
    //   text: t('us_accredited_investor'),
    //   onClick: () => {
    //     window.open(kycInfo?.usInvestorsLink, '_blank', 'noopener,noreferrer')
    //   },
    // },
    { text: 'venture_capital', disabled: true },
  ]

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {isRejectedFinal ? (
        <KYCRejectedFinal />
      ) : (
        <>
          <div className="mb-[1rem] flex justify-start">
            <WarningIcon className="relative top-[0.13rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />
            <div className="text-[0.7rem] text-gray">
              {t('requires_kyc_text')}
            </div>
          </div>
          {kycStatus === 'notVerified' && (
            <Dropdown items={applyOptions} className="w-full">
              {t('apply_for_kyc')}
            </Dropdown>
          )}
          {kycStatus === 'rejectedRetry' && (
            <Button
              className="w-full uppercase"
              onClick={() => {
                window.open(
                  kycInfo?.account?.resubmitLink,
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
            >
              {t('finalize_kyc')}
            </Button>
          )}
        </>
      )}
    </TerminalWrapper>
  )
}

function TerminalStaking({
  countdownTime,
  countdownTitle,
  stakingSymbol,
}: TerminalStateProps) {
  const navigate = useNavigate()

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <div className="mb-[1rem] flex justify-start">
        <WarningIcon className="relative top-[0.13rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />
        <div className="text-[0.7rem] text-gray">
          {t('insufficient_token_stake', { symbol: stakingSymbol })}
        </div>
      </div>
      <Button className="w-full uppercase" onClick={() => navigate('/staking')}>
        {t('go_to_staking')}
      </Button>
    </TerminalWrapper>
  )
}

function TerminalResidence({
  countdownTime,
  countdownTitle,
}: TerminalStateProps) {
  const [checked, setChecked] = useState(false)

  const handleToggle = () => {
    setChecked(!checked)
  }

  const handleButtonClick = () => {
    setResidenceConfirmed(true)
  }

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <div>
        <div className="mb-[1rem] flex">
          <input
            onClick={handleToggle}
            type="checkbox"
            name="resident-agreement"
            className={`
                      relative mr-[0.66rem] h-[0.9rem] w-[0.9rem] cursor-pointer appearance-none rounded-none border border-gray9A bg-grayLight
                      after:absolute after:top-[0.1rem] after:left-[0.1rem] after:hidden after:h-[0.55rem] after:w-[0.55rem] after:bg-checkboxCheck after:bg-cover checked:after:block
                    `}
          />
          <label className="relative top-[-0.1rem] w-full text-[0.7rem] text-gray">
            {t('resident_text')}
          </label>
        </div>
        <Button
          className="w-full uppercase"
          disabled={!checked}
          onClick={handleButtonClick}
        >
          {t('proceed')}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalComingSoon({
  countdownTime,
  countdownTitle,
  isPrivate,
}: TerminalStateProps) {
  return (
    <>
      {!isPrivate ? (
        <TerminalWrapper
          countdownTime={countdownTime}
          countdownTitle={countdownTitle}
        >
          {/* <PrivateAndNonParticipatingTip /> */}
          <div>
            {/* <Button className="w-full uppercase" disabled>
             {t('register')}
           </Button> */}
          </div>
        </TerminalWrapper>
      ) : (
        <TerminalWrapper
          countdownTime={countdownTime}
          countdownTitle={countdownTitle}
        >
          <PrivateAndNonParticipatingTip />
          <div>
            <Button className="w-full uppercase" disabled>
              {t('register')}
            </Button>
          </div>
        </TerminalWrapper>
      )}
    </>
  )
}

function TerminalRegistration({
  countdownTime,
  countdownTitle,
  isRegistered,
}: TerminalStateProps) {
  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {!isRegistered ? (
        <PrivateAndNonParticipatingTip />
      ) : (
        <div className="mb-[1rem] flex justify-start">
          <SuccessIcon className="relative top-[0.18rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />
          <div className="text-[0.7rem] text-gray">
            {t('you_are_registered')}
          </div>
        </div>
      )}

      <div>
        <Button
          className="w-full uppercase"
          disabled={isRegistered}
          onClick={() => {
            setRegisterModalStatus('open')
          }}
        >
          {isRegistered ? 'Buy' : 'Register'}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalNotEligible({
  countdownTime,
  countdownTitle,
  poolStatus,
}: TerminalStateProps) {
  if (
    poolStatus === PoolStatus.Prelaunch ||
    poolStatus === PoolStatus.InProgress
  ) {
    return (
      <TerminalWrapper
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      >
        <NotEligibleTip />
      </TerminalWrapper>
    )
  }
  if (
    poolStatus === PoolStatus.Failed ||
    poolStatus === PoolStatus.SoldOut ||
    poolStatus === PoolStatus.SuccessfullyFinished
  ) {
    return (
      <TerminalWrapper
        countdownTime={countdownTime}
        countdownTitle={countdownTitle}
      >
        <PrivateAndNonParticipatingTip />
      </TerminalWrapper>
    )
  }
  return null
}

function TerminalPrelaunch({
  countdownTime,
  countdownTitle,
  isPrivate,
  isWhitelisted,
}: TerminalStateProps) {
  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {isPrivate && (
        <div className="mb-[1rem] flex">
          <WatchIcon className="relative top-[0.13rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />

          <div className="text-[0.7rem] text-gray">
            {isWhitelisted
              ? `Whitelisting results are in. Follow your personal countdown and don't miss your turn to participate. Good luck.`
              : 'Whitelisting results coming soon'}
          </div>
        </div>
      )}
      <div>
        <Button className="w-full uppercase" disabled>
          {t('buy')}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalSale({
  pool,
  countdownTime,
  countdownTitle,
  usedAllocationComputed,
  idoTokensBoughtComputed,
  idoTokenSymbol,
  targetTokenSymbol,
  walletBalanceDec,
  walletBalanceComputed,
  availableAllocation,
  price,
}: TerminalStateProps) {
  const session = useStore($session)
  const [value, setValue] = useState('')

  const setMaxAvailableAllocation = () => {
    const fee = toDecimal('0.0005') // todo: calculate fee
    const maxAvailableAllocation = Decimal.max(
      Decimal.min(availableAllocation!, walletBalanceDec!).minus(fee),
      0
    )
    setValue(maxAvailableAllocation.toString())
  }

  const handleTypeInput = (value: string) => {
    setValue(value)
  }

  const buy = () => {
    buyFx({ pool: pool!, rawAmount: value }).then(() => setValue(''))
  }

  const youReceiveValue = toDecimal(value).div(toDecimal(price))

  const amountDecimal = toDecimal(value ?? '0')

  const buyText = () => {
    if (toDecimal(availableAllocation).eq(0)) {
      return t('buy')
    }
    if (value === '') {
      return t('enter_an_amount')
    }
    if (amountDecimal.greaterThan(walletBalanceDec!)) {
      return t('insufficient_balance')
    }
    if (amountDecimal.greaterThan(toDecimal(availableAllocation))) {
      return t('allocation_exceeded')
    }
    return t('buy')
  }

  const buttonDisabled =
    value === '' ||
    amountDecimal.lessThan(toDecimal(0.0000001)) ||
    amountDecimal.greaterThan(walletBalanceDec!) ||
    amountDecimal.greaterThan(toDecimal(availableAllocation))

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      <div>
        <div className="flex flex-col items-center justify-between ml:flex-row">
          <CurrencyInputPanel
            className="w-[100%] ml:w-[45%]"
            label={t('you_send')}
            leftSub={t('used_alloc')}
            leftCount={usedAllocationComputed}
            rightSub={`${isEmpty(session) ? '' : 'Wallet'}`}
            rightCount={`${isEmpty(session) ? '' : walletBalanceComputed}`}
            symbol={targetTokenSymbol}
            value={value}
            showMaxButton={!isEmpty(session)}
            onUserInput={handleTypeInput}
            onMax={setMaxAvailableAllocation}
          />
          <ArrowRight className="relative top-[-0.5rem] h-[0.9rem] w-[0.9rem] rotate-90 ml:grow ml:rotate-0" />
          <CurrencyInputPanel
            className="w-[100%] ml:w-[45%]"
            label={t('you_receive')}
            leftSub={t('tokens_bought')}
            leftCount={idoTokensBoughtComputed}
            symbol={idoTokenSymbol}
            value={formatNumber(youReceiveValue, {
              fractionDigits: 6,
            })}
            disabled
          />
        </div>
        <Button
          className="w-full uppercase"
          disabled={buttonDisabled}
          onClick={buy}
        >
          {buyText()}
        </Button>
      </div>
    </TerminalWrapper>
  )
}

function TerminalClaim({
  pool,
  countdownTime,
  countdownTitle,
  idoTokenSymbol,
  idoTokensBoughtDec,
  vestingTotalAvailable,
  userToReserve,
  claimed,
  vested,
  allowedToClaim,
  privateAndNonParticipating,
}: TerminalStateProps) {
  const claim = () => {
    claimFx(pool!)
  }

  return (
    <TerminalWrapper
      countdownTime={countdownTime}
      countdownTitle={countdownTitle}
    >
      {privateAndNonParticipating ? (
        <PrivateAndNonParticipatingTip />
      ) : (
        <div>
          <div className="flex flex-col items-center justify-between ml:flex-row">
            <CurrencyInputPanel
              className="w-[100%] ml:w-[45%]"
              symbol={idoTokenSymbol}
              label={t('tokens_bought')}
              value={formatNumber(idoTokensBoughtDec, {
                fractionDigits: 4,
              })}
              leftSub={t('vested')}
              leftCount={`${vested}/${userToReserve}`}
              disabled
            />
            <div className="w-[0.9rem]" />
            <CurrencyInputPanel
              className="w-[100%] ml:w-[45%]"
              symbol={idoTokenSymbol}
              label={t('available_claim')}
              value={formatNumber(vestingTotalAvailable ?? 0, {
                fractionDigits: 4,
              })}
              leftSub={t('claimed')}
              leftCount={`${claimed}/${userToReserve}`}
              disabled
            />
          </div>
          <Button
            className="w-full uppercase"
            disabled={!allowedToClaim}
            onClick={claim}
          >
            {t('claim')}
          </Button>
        </div>
      )}
    </TerminalWrapper>
  )
}

function TerminalRefund({
  pool,
  targetTokenSymbol,
  availableToRefund,
}: TerminalStateProps) {
  const refund = () => {
    refundFx(pool!)
  }
  return (
    <TerminalWrapper>
      <CurrencyInputPanel
        disabled
        label={t('available_for_refund')}
        symbol={targetTokenSymbol}
        value={availableToRefund!}
      />
      <Button
        className="mt-[0.8rem] w-full uppercase"
        onClick={refund}
        disabled={availableToRefund === '0'}
      >
        {t('refund')}
      </Button>
    </TerminalWrapper>
  )
}

export type TerminalWrapperProps = {
  children: ReactNode
  countdownTime?: string | null
  countdownTitle?: string | null
  title?: string
}

function TerminalWrapper({
  children,
  countdownTime,
  countdownTitle,
  title,
}: TerminalWrapperProps) {
  return (
    <div className="relative z-20 flex h-full flex-col justify-between bg-graySecondary p-[1rem] font-light drop-shadow-blockShadow">
      <div>
        <TerminalBlockDecor className="absolute right-0 top-[-1.19rem] z-0 h-[1.2rem] w-[7.35rem]" />
        <div className="mb-[0.4rem] text-[1.2rem]">
          {title ? title : t('participation_terminal')}
        </div>
        <Timer endTime={countdownTime} text={countdownTitle} />
      </div>
      <div>{children}</div>
    </div>
  )
}

function NotEligibleTip() {
  return (
    <div className="mb-[1rem] flex">
      <RejectIcon className="relative top-[0.13rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />
      <div className="text-[0.7rem] text-gray">
        {t('not_eligible_tip', {
          link: (
            <a href="/" className="underline hover:no-underline">
              {t('ido_process')}
            </a>
          ),
        })}
      </div>
    </div>
  )
}

function KYCRejectedFinal() {
  return (
    <div className="mb-[1rem] flex justify-center">
      <RejectIcon className="relative top-[0.13rem] mr-[0.5rem] h-[0.8rem] w-[0.8rem]" />
      <div className="text-[0.7rem] text-gray">
        {t('kyc_rejected_final_tip')}
      </div>
    </div>
  )
}

function PrivateAndNonParticipatingTip() {
  const { stakingSymbol } = useStore($staking)

  return (
    <div className="mb-[1rem] flex">
      <InfoIcon className="mr-[0.5rem] h-[1.1rem] w-[1.1rem] grow" />
      <div className="grow text-[0.7rem] text-gray">
        {t('privat_and_non_participating_tip', {
          symbol: stakingSymbol,
          link: (
            <a href="/" className="underline hover:no-underline">
              {t('ido_process')}
            </a>
          ),
        })}
      </div>
    </div>
  )
}

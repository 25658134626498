import Button from 'components/dumb/Button'
import { ReactComponent as PookIcon } from 'resources/images/icons/pook.svg'
import WindowsContainer, {
  TripleShadowText,
} from 'components/dumb/WindowsContainer'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import { useNavigate } from 'react-router-dom'
import t from 'utils/t'

export default function SmartHomeStats() {
  const staking = useStore($staking)
  const navigate = useNavigate()

  return (
    <>
      <WindowsContainer
        title={`${staking.stakingSymbol} Staking APR`}
        className="justify-between"
      >
        <TripleShadowText className="text-[1.2rem] dxs:text-[1.7rem]">
          {staking.apy}
        </TripleShadowText>
        <Button
          type="green"
          className="w-fit !shadow-none"
          onClick={() => {
            navigate('/staking')
          }}
        >
          {t('stake_symbol', { symbol: staking.stakingSymbol })}
        </Button>
      </WindowsContainer>
      <div className="hidden ml:block dxs:hidden" />
      <WindowsContainer
        title={`${staking.rewardSymbol} to be mined`}
        className="justify-between"
      >
        <TripleShadowText className="text-[1.2rem] dxs:text-[1.7rem]">
          {staking.leftToBeMined}
        </TripleShadowText>
        <Button
          type="green"
          className="w-fit !shadow-none"
          onClick={() => {
            navigate('/dashboard')
          }}
        >
          {t('go_to_dashboard')}
        </Button>
      </WindowsContainer>
      <WindowsContainer title={t('apply_for_ido')} className="justify-between">
        <p className="relative z-10 w-[80%] font-manrope text-[0.6rem] leading-[140%] text-grayLight ml:w-full t:text-[0.7rem]">
          {t('apply_for_ido_text')}
        </p>
        <Button
          type="green"
          className="w-fit !shadow-none"
          onClick={() => {
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLSc53MeP7kv5Z_7c_Pq1K5q0kioEhWXHdehg96znwMuMs7MloA/viewform',
              '_blank',
              'noopener,noreferrer'
            )
          }}
        >
          {t('apply_for_ido')}
        </Button>

        <PookIcon
          className={`
            absolute right-[0.7rem] bottom-[1rem] block h-[4.3rem] w-[4.3rem] 
            ml:hidden dxs:block
          `}
        />
      </WindowsContainer>
    </>
  )
}

import { ReactNode } from 'react'
import { ReactComponent as DecorCenter } from 'resources/images/header-center-decor.svg'
import { ReactComponent as DecorLeft } from 'resources/images/header-left-decor.svg'
import { ReactComponent as HeaderLeftIcon } from 'resources/images/header-left-icon.svg'
import { ReactComponent as DecorRight } from 'resources/images/header-right-decor.svg'
import { ReactComponent as HeaderRightIcon } from 'resources/images/header-right-icon.svg'
import headerTopBg from 'resources/images/header-top-bg.svg'

interface Props {
  children?: ReactNode
}

export default function Header({ children }: Props) {
  return (
    <div className="z-3 relative flex h-auto flex-col bg-grayLight dxs:h-[80px]">
      <div
        className="flex h-[27px] flex-row justify-between"
        style={{ backgroundImage: `url(${headerTopBg})` }}
      >
        <HeaderLeftIcon />
        <HeaderRightIcon />
      </div>
      <div className="flex-1">
        <div
          className={`
          m-auto mt-2 flex h-full w-[300px] flex-col-reverse items-stretch justify-between mm:w-[90%]
          ml:w-[590px] t:w-[80%] dxs:mt-0 dxs:w-[1000px] dxs:flex-row dxs:items-start ds:w-[1200px]
        `}
        >
          {children}
        </div>
      </div>
      <div className="relative">
        <DecorLeft className="absolute hidden dxs:block" />
        <DecorCenter className="absolute left-[649px] hidden dxs:block" />
        <DecorRight className="absolute right-0 hidden dxs:block" />
      </div>
    </div>
  )
}

import SocialLink, { SocialLinkIconType } from 'components/dumb/SocialLink'
import React from 'react'
import { useLocation } from 'react-router-dom'
import auroraLogoURL from 'resources/images/footer/aurora-logo.png'

export default function Footer() {
  const location = useLocation()

  const { marginTop, img, mOffsetTop, imgOffset, w, h } = React.useMemo(() => {
    if (location.pathname.includes('/pool')) {
      return {
        w: 'w-[296px] dxs:w-[200px] ds:w-[296px]',
        h: 'h-[296px] dxs:h-[200px] ds:h-[296px]',
        marginTop: 'mt-[70px] ml:mt-[122px] dxs:mt-[50px]',
        img: 'bg-footerAstronaut ',
        imgOffset:
          '-left-[270px] -top-[160px] dxs:-top-[75px] ds:-top-[160px] dxs:-left-[160px] ds:-left-[270px]',
        mOffsetTop: '-top-[38%] mm:-top-[40%] ml:-top-[35%]',
      }
    }

    switch (location.pathname) {
      case '/':
        return {
          w: 'w-[296px] dxs:w-[200px] ds:w-[296px]',
          h: 'h-[296px] dxs:h-[200px] ds:h-[296px]',
          marginTop: 'mt-[70px] ml:mt-[122px] dxs:mt-[50px]',
          img: 'bg-footerAstronaut ',
          imgOffset:
            '-left-[270px] -top-[160px] dxs:-top-[75px] ds:-top-[160px] dxs:-left-[160px] ds:-left-[270px]',
          mOffsetTop: '-top-[38%] mm:-top-[40%] ml:-top-[35%]',
        }
      case '/staking':
        return {
          w: '',
          h: '',
          marginTop: 'mt-[156px]',
          img: '',
          imgOffset: '',
          mOffsetTop: '',
        }
      case '/dashboard':
        return {
          w: 'w-[324px] mm:w-[352px]',
          h: 'h-[230px] mm:h-[248px]',
          marginTop: 'mt-[70px] ml:mt-[122px] dxs:mt-[50px]',
          img: 'bg-footerWhale dxs:scale-[0.70] ds:scale-100',
          imgOffset:
            '-left-[80%] dxs:-left-[90%] ds:-left-[302px] -top-[140px]',
          mOffsetTop: '-top-[30%]',
        }
      default:
        return {
          w: '',
          h: '',
          marginTop: '',
          img: '',
          imgOffset: '',
          mOffsetTop: '',
        }
    }
  }, [location.pathname])

  return (
    <div className="relative mt-[7rem] w-full border-t-[0.4rem] border-grayDA bg-grayLight dxs:mt-0">
      <div
        className={`w-full border-t-[0.05rem] border-grayDA ${marginTop} `}
      />
      <div
        className={`absolute ${mOffsetTop} left-1/2 -translate-x-1/2 transform ${h} ${w} block bg-contain bg-no-repeat dxs:!hidden ${img} `}
      />
      <div className="m-auto w-full dxs:w-[1000px] ds:w-[1200px]">
        <div className="grid w-full grid-cols-1 justify-items-center dxs:grid-cols-[1.5fr,auto,2fr]">
          <div className="grid grid-cols-[repeat(2,fit-content(30px))] gap-x-[20px] gap-y-[8px] border-0 border-grayDA py-[40px] font-manrope text-[14px] mm:grid-cols-[repeat(3,fit-content(30px))] mm:p-[40px] ml:gap-x-[40px] dxs:grid-cols-[fit-content(50px),1fr,1fr] dxs:border-x-[1px]">
            <FooterPlainTextLink href="https://docsend.com/view/2snj5japeg2xhthb">
              Deck
            </FooterPlainTextLink>
            <FooterPlainTextLink href="https://docs.google.com/forms/d/e/1FAIpQLSc53MeP7kv5Z_7c_Pq1K5q0kioEhWXHdehg96znwMuMs7MloA/viewform">
              Apply for IDO
            </FooterPlainTextLink>
            <FooterPlainTextLink href="/pdf/nearstarter-terms-and-conditions.pdf">
              Terms of Use
            </FooterPlainTextLink>
            <FooterPlainTextLink href="https://hackmd.io/@NEARStarter/HJUVLyJ3q">
              FAQ
            </FooterPlainTextLink>
            <FooterPlainTextLink href="mailto:contact@nearstarter.fi">
              Contact Us
            </FooterPlainTextLink>
            <FooterPlainTextLink href="/pdf/nearstarter-privacy-policy.pdf">
              Privacy Policy
            </FooterPlainTextLink>
          </div>
          <div className="w-full border-t-[1px] border-grayDA dxs:hidden" />
          <div className="grid grid-cols-2 gap-x-[40px] gap-y-[8px] border-r-0 border-grayDA p-[40px] ml:grid-cols-4 dxs:grid-cols-2 dxs:border-r-[1px]">
            <FooterSocialLink
              url="https://t.me/NEARStarterCommunity"
              icon="telegram"
            >
              Telegram
            </FooterSocialLink>
            <FooterSocialLink
              url="https://twitter.com/NEARStarter_fi"
              icon="twitter"
            >
              Twitter
            </FooterSocialLink>
            <FooterSocialLink
              url="https://medium.com/near-starter"
              icon="medium"
            >
              Medium
            </FooterSocialLink>
            <FooterSocialLink
              url="https://discord.gg/CayJU22phq"
              icon="discord"
            >
              Discord
            </FooterSocialLink>
          </div>
          <div className="w-full border-t-[1px] border-grayDA dxs:hidden" />
          <div className="relative flex flex-col items-end justify-self-center border-r-0 border-grayDA p-[40px] dxs:justify-self-end dxs:border-r">
            <div
              className={`absolute -top-[140px] ${imgOffset} ${h} ${w} hidden bg-contain bg-no-repeat dxs:block ${img} `}
            />
            <div className="relative right-[0.8rem] font-codersCrux text-[24px] leading-none">
              BUILT ON AND FOR
            </div>
            <img
              src={auroraLogoURL}
              alt="Aurora blockchain"
              className="relative top-[-0.5rem] w-[10rem]"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface FooterSocialLinkProps {
  url: string
  children: React.ReactNode
  icon: SocialLinkIconType
}

const FooterSocialLink = ({ children, url, icon }: FooterSocialLinkProps) => {
  return (
    <div className="flex">
      <div className="group flex items-center space-x-[8px] font-manrope text-[14px]">
        <SocialLink
          className="cursor-pointer bg-transparent text-blackSecondary group-hover:text-pink"
          url={url}
          icon={icon}
        />
        <FooterPlainTextLink href={url} className="group-hover:text-pink">
          {children}
        </FooterPlainTextLink>
      </div>
    </div>
  )
}
interface FooterLinkProps {
  href: string
  children: React.ReactNode
  className?: string
}

const FooterPlainTextLink = ({
  children,
  href,
  className = '',
}: FooterLinkProps) => {
  return (
    <a
      className={`whitespace-nowrap text-black hover:text-pink active:text-pinkActive ${className}`}
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  )
}

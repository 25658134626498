import classNames from 'classnames'
import Button from 'components/dumb/Button'
import SmartWalletButton from 'components/smart/SmartHeader/SmartWalletButton'
import { useStore } from 'effector-react'
import { $kyc, $residenceConfirmed } from 'models/generalInfo'
import { $staking } from 'models/staking'
import { $networkIsOk } from 'models/wallet'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckIcon } from 'resources/images/icons/check-circle.svg'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-up.svg'
import { noop } from 'utils'
import t from 'utils/t'

export interface ParticipationGuideProps {
  isPublic: boolean
  isRegistered?: boolean
  connected: boolean
  idoTokenSymbol: string
  comingSoon: boolean
  registration: boolean
  finished: boolean
  tokensHasBeenDistributed: boolean
  userParticipated: boolean
}

const steps = [
  'ConnectWallet',
  'PassKYC',
  'ConfirmResidency',
  'Stake',
  'Register',
  'Buy',
  'Claim',
  'None',
] as const
type StepType = typeof steps[number]

export default function SmartProjectPageParticipationGuide({
  connected,
  idoTokenSymbol,
  terminalRef,
  isPublic,
  finished,
  comingSoon,
  registration,
  isRegistered,
  tokensHasBeenDistributed,
  userParticipated,
}: ParticipationGuideProps & { terminalRef: any }) {
  const navigate = useNavigate()
  const residenceConfirmed = useStore($residenceConfirmed)
  const networkIsOk = useStore($networkIsOk)
  const { stakingSymbol, level: tierLevel } = useStore($staking)
  const kyc = useStore($kyc)
  const passedKYC = kyc.status === 'verified'
  const notEnoughStake = tierLevel === 0

  const handleScrollToTerminalButtonClick = useCallback(() => {
    if (terminalRef && terminalRef.current) {
      terminalRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [terminalRef])

  const handleGoToStake = () => {
    navigate('/staking')
  }

  const activeStep: StepType = React.useMemo(() => {
    if (!connected || !networkIsOk) {
      return 'ConnectWallet'
    }

    if (isPublic) {
      if (finished && !userParticipated) {
        return 'None'
      }
      if (!residenceConfirmed) {
        return 'ConfirmResidency'
      } else if (!finished) {
        return 'Buy'
      } else if (!tokensHasBeenDistributed) {
        return 'Claim'
      }
      return 'None'
    }

    if (!passedKYC) {
      return 'PassKYC'
    } else if (notEnoughStake || comingSoon) {
      return 'Stake'
    } else if (!isRegistered && registration) {
      return 'Register'
    } else if (isRegistered && !finished) {
      return 'Buy'
    } else if (finished && !tokensHasBeenDistributed) {
      return 'Claim'
    }
    return 'None'
  }, [
    finished,
    comingSoon,
    connected,
    networkIsOk,
    tokensHasBeenDistributed,
    notEnoughStake,
    isRegistered,
    registration,
    residenceConfirmed,
    isPublic,
    passedKYC,
    userParticipated,
  ])

  const items = useMemo(() => {
    const finishedAndNotParticipated = finished && !userParticipated
    const activeStepIndex = finishedAndNotParticipated
      ? -1
      : steps.indexOf(activeStep)

    return [
      {
        title: t('connect_wallet'),
        desc: t('connect_wallet_description'),
        button: <SmartWalletButton className="w-full !shadow-none" />,
        isActive: activeStep === 'ConnectWallet',
        completed: activeStepIndex > 0,
        hide: false,
      },
      {
        title: t('get_verified'),
        desc: t('get_verified_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            className="w-full !shadow-none"
          >
            {t('apply_for_kyc')}
          </Button>
        ),
        isActive: activeStep === 'PassKYC',
        completed: activeStepIndex > 0,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('confirm_residency'),
        desc: t('confirm_residency_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            className="w-full !shadow-none"
          >
            {t('proceed')}
          </Button>
        ),
        isActive: activeStep === 'ConfirmResidency',
        completed: activeStepIndex > 2,
        onClick: noop,
        hide: !isPublic,
      },
      {
        title: t('stake_token', { symbol: stakingSymbol }),
        desc: t('stake_token_description'),
        button: (
          <Button
            onClick={handleGoToStake}
            className="w-full !shadow-none"
            disabled={!notEnoughStake}
          >
            Stake
          </Button>
        ),
        isActive: activeStep === 'Stake',
        completed: activeStepIndex > 3,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('register_for_an_ido'),
        desc: t('register_for_an_ido_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            className="w-full !shadow-none"
            disabled={isRegistered}
          >
            {t('register')}
          </Button>
        ),
        isActive: activeStep === 'Register',
        completed: activeStepIndex > 4,
        onClick: noop,
        hide: isPublic,
      },
      {
        title: t('buy_token', { symbol: idoTokenSymbol }),
        desc: t('buy_token_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            className="w-full !shadow-none"
          >
            {t('buy')}
          </Button>
        ),
        isActive: activeStep === 'Buy',
        completed: activeStepIndex > 5,
        onClick: noop,
        hide: false,
      },
      {
        title: t('claim_token', { symbol: idoTokenSymbol }),
        desc: t('claim_token_description'),
        button: (
          <Button
            onClick={handleScrollToTerminalButtonClick}
            className="w-full !shadow-none"
          >
            {t('claim')}
          </Button>
        ),
        isActive: activeStep === 'Claim',
        completed: activeStepIndex > 6,
        onClick: noop,
        hide: false,
      },
    ].filter((item) => item.hide === false)
  }, [
    activeStep,
    stakingSymbol,
    idoTokenSymbol,
    isPublic,
    handleScrollToTerminalButtonClick,
    handleGoToStake,
    notEnoughStake,
    isRegistered,
    finished,
    userParticipated,
  ])

  const gridClassNames = isPublic
    ? 'grid-cols-1 ml:grid-cols-2 dxs:grid-cols-4'
    : 'grid-cols-1 ml:grid-cols-3 ds:grid-cols-6'

  const [guideIsOpen, setGuideIsOpen] = useState(false)

  return (
    <div className="drop-shadow-blockShadow">
      <div className=" mb-[2.4rem] flex flex-col overflow-hidden border border-gray9A bg-black px-[1rem]">
        <div
          className="group flex cursor-pointer items-center justify-between py-[1rem] text-[1.2rem] font-light text-grayLight"
          onClick={() => setGuideIsOpen(!guideIsOpen)}
        >
          <div>{t('how_to_participate')}</div>
          <div className="bg-pink bg-opacity-15 p-[0.5rem] transition-colors group-hover:bg-opacity-30">
            <ChevronIcon
              className={`
              h-[0.9rem] w-[0.9rem] transition-transform
              ${guideIsOpen ? 'rotate-180' : 'rotate-0'}
           `}
            />
          </div>
        </div>
        <div
          className={`
          grid auto-rows-auto justify-between gap-[0.6rem] ml:auto-rows-[1fr] 
          ${gridClassNames}
          ${guideIsOpen ? '  pb-[1rem] dxs:min-h-full' : 'max-h-0 min-h-0'}
        `}
        >
          {items.map((item, index) => (
            <Entry
              key={`how-to-participate-${item.title}`}
              stepNumber={index + 1}
              buttonType={index === 0 && !networkIsOk ? 'switch' : 'connect'}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

interface EntryProps {
  title: string
  desc: string
  button: React.ReactNode
  isActive: boolean
  completed: boolean
  onClick?(): void
}

function Entry({
  stepNumber,
  isActive,
  completed,
  button,
  title,
  desc,
}: EntryProps & {
  stepNumber: number
  buttonType?: 'switch' | 'connect'
}) {
  const base = 'p-[0.6rem] font-manrope'
  const active = 'border border-gray9A bg-pink/15'

  return (
    <div
      className={classNames(base, {
        [active]: isActive,
      })}
    >
      <div className="relative mb-[0.6rem] h-[1.5rem] w-[1.5rem] bg-pink/15">
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.8rem] text-pink">
          {completed ? <CheckIcon /> : stepNumber}
        </span>
      </div>
      <div className="mb-[0.2rem] text-[0.8rem] leading-[140%] text-pink">
        {title}
      </div>
      <div className="mb-[0.6rem] text-[0.7rem] leading-[140%] text-pink">
        {desc}
      </div>
      {isActive && button}
    </div>
  )
}

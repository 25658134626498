import Decimal from 'decimal.js'
import { attach, combine, createEvent, createStore } from 'effector'
import { createGate } from 'effector-react'
import { ethers } from 'ethers'
import { $generalInfo } from 'models/generalInfo'
import { $address, $provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { toDecimal } from 'utils/numbers'
import { stakingAbi, tokenAbi } from './abi'
import { Staking } from './staking'

export const WalletBalanceGate = createGate()

export const stakingTick = createEvent()

export const $stakingTokenWalletBalanceDec = createStore(new Decimal(0))
export const $stakingTokenWalletBalance = $stakingTokenWalletBalanceDec.map(
  (d) => d.toString()
)

export const $staking = combine(
  $stakingTokenWalletBalanceDec,
  $generalInfo
).map(([balance, data]) => new Staking(balance, data?.stakingInfo))

export const addStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider], rawAmount: string) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const mlt = toDecimal(10).pow(staking.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const res = await stakingContract.stake(amount)
    return `${res?.hash ?? ''}`
  },
})

export const approveStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider]) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const signer = provider.getSigner()
    const tokenContract = new ethers.Contract(
      staking.stakingTokenAddress,
      tokenAbi,
      signer
    )

    const res = await tokenContract.approve(
      staking.address,
      '10000000000000000000000000000000000'
    )
    return `${res?.hash ?? ''}`
  },
})

export const removeStakeFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider], rawAmount: string) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const mlt = toDecimal(10).pow(staking.stakingTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()
    const maximumFee = toDecimal(rawAmount)
      .mul(mlt)
      .mul(staking.unstakingFeeRatioDec)
      .ceil()
      .toString()

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const res = await stakingContract.unstake(amount, maximumFee, {
      gasLimit: 200000,
    })

    return `${res?.hash ?? ''}`
  },
})

export const getRewardFx = attach({
  source: [$staking, $provider],
  async effect([staking, provider]) {
    invariant(provider, 'web3 provider not found')
    invariant(staking.address, 'staking contract not specified')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const signer = provider.getSigner()
    const stakingContract = new ethers.Contract(
      staking.address,
      stakingAbi,
      signer
    )

    const res = await stakingContract.getReward()
    return `${res?.hash ?? ''}`
  },
})

export const fetchStakingTokenWalletBalanceFx = attach({
  source: [$address, $staking, $provider],
  async effect([address, staking, provider]) {
    invariant(provider, 'web3 provider not found')

    if (address === '') {
      return new Decimal(0)
    }

    const contract = new ethers.Contract(
      staking.stakingTokenAddress,
      tokenAbi,
      provider
    )
    const balance = await contract.balanceOf(address)

    const denom = new Decimal(10).pow(staking.stakingTokenDecimals)
    return new Decimal(balance?._hex ?? 0).div(denom)
  },
})

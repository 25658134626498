import Button from 'components/dumb/Button'
// import Dropdown from 'components/dumb/Dropdown'
import Field, { Props as FieldProps } from 'components/dumb/Field'
import WindowsContainer, {
  TripleShadowText,
} from 'components/dumb/WindowsContainer'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import t from 'utils/t'

export default function SmartStakingStats() {
  const staking = useStore($staking)

  const fields: FieldProps[] = [
    {
      icon: 'left',
      title: t('left_to_be_mined'),
      value: staking.leftToBeMined,
      symbol: staking.rewardSymbol,
      sub: `/${staking.totalRewardToDistribute}`,
    },
    {
      icon: 'staked',
      title: t('total_staked'),
      value: staking.totalStaked,
      symbol: staking.stakingSymbol,
      sub: `/${staking.totalStakingSupply}`,
    },
    {
      icon: 'mine',
      title: t('mined_per_day'),
      value: staking.minedPerDay,
      symbol: staking.minedPerDay === 'TBA' ? '' : staking.rewardSymbol,
    },
    // {
    //   icon: 'unique-stackers',
    //   title: 'Unique Stackers',
    //   value: staking.uniqueStakers,
    //   symbol: staking.stakingSymbol,
    // },
  ]

  return (
    <div className="row-span-2">
      <div className="gap-x mb-[1.6rem] grid grid-cols-1 gap-y-4 ml:grid-cols-2 ml:gap-y-[1.6rem]">
        {fields.map((fieldProps) => (
          <Field
            key={fieldProps.title}
            valueClassName="!text-greenLight"
            symbolClassName="!text-greenLight"
            {...fieldProps}
          />
        ))}
      </div>

      <WindowsContainer
        title={`${staking.stakingSymbol} Staking Annual Percentage Return`}
      >
        <div className="grid grid-cols-1 gap-y-4 ml:grid-cols-[2fr,1fr] ml:gap-y-0">
          <TripleShadowText className="row-span-3 text-[1.8rem]">
            {staking.apy}
          </TripleShadowText>
          <div className="flex flex-col space-y-[0.5rem]">
            {/* todo: links and texts "trade on ..." */}
            <Button disabled>TBA</Button>
          </div>
        </div>
      </WindowsContainer>
    </div>
  )
}

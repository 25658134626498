import Button from 'components/dumb/Button'
import ProjectPageTier from 'components/dumb/ProjectPageTier'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ConnectTierIcon } from 'resources/images/icons/tiers/connect.svg'
import { ReactComponent as NoTier } from 'resources/images/icons/tiers/no-tier.svg'
import { ReactComponent as Tier1 } from 'resources/images/icons/tiers/tier1.svg'
import { ReactComponent as Tier2 } from 'resources/images/icons/tiers/tier2.svg'
import { ReactComponent as Tier3 } from 'resources/images/icons/tiers/tier3.svg'
import { ReactComponent as Tier4 } from 'resources/images/icons/tiers/tier4.svg'
import { ReactComponent as Tier5 } from 'resources/images/icons/tiers/tier5.svg'
import { $staking } from 'models/staking'
import { useStore } from 'effector-react'
import t from 'utils/t'
import { openWallet } from 'models/wallet'

const TIER_CN = 'absolute right-[-1.9rem] h-[18rem] w-[18rem]'

export type Props = (
  | {
      state: 'notConnected' | 'noTier' | 'noTierRequirements' | 'tier1'
    }
  | {
      state: 'tier2' | 'tier3' | 'tier4' | 'tier5'
      moreTokens: string
      betterChance: string
    }
) & { hide?: boolean; hideImage: boolean }

export default function ProjectPageTierMaster(props: Props) {
  if (props.hide) return null

  switch (props.state) {
    case 'noTier':
      return <NoTierState {...props} />
    case 'noTierRequirements':
      return <NoTierRequirementsState {...props} />
    case 'notConnected':
      return <NotConnectedState {...props} />
    case 'tier1':
      return <Tier1State {...props} />
    case 'tier2':
      return <Tier2State {...props} />
    case 'tier3':
      return <Tier3State {...props} />
    case 'tier4':
      return <Tier4State {...props} />
    case 'tier5':
      return <Tier5State {...props} />
    default:
      return null
  }
}

function NotConnectedState({ hideImage }: Props) {
  return (
    <ProjectPageTier
      title={t('get_your_tier')}
      description={t('connect_wallet_tier_text')}
      tierImage={
        <ConnectTierIcon className="absolute bottom-[-1.2rem] right-0 z-0 h-[20.75rem] w-[17rem]" />
      }
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => {
            openWallet()
          }}
        >
          {t('connect_wallet')}
        </Button>
      }
    />
  )
}

function NoTierState({ hideImage }: Props) {
  const navigate = useNavigate()

  return (
    <ProjectPageTier
      title={t('get_your_tier')}
      description={t('missed_ido')}
      tierImage={
        <NoTier className="absolute bottom-[-1.2rem] right-0 z-0 h-[20.75rem] w-[17rem]" />
      }
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function NoTierRequirementsState({ hideImage }: Props) {
  const navigate = useNavigate()
  const { stakingSymbol } = useStore($staking)

  return (
    <ProjectPageTier
      title={t('no_tier_requirements')}
      description={t('no_tier_requirements_text', { symbol: stakingSymbol })}
      tierImage={<Tier4 className={TIER_CN} />}
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function Tier1State({ hideImage }: Props) {
  return (
    <ProjectPageTier
      title={
        <>
          <span className="text-pink">Lunapunk</span> Tier
        </>
      }
      description={t('highest_tier_text')}
      tierImage={<Tier1 className={TIER_CN} />}
      hideImage={hideImage}
    />
  )
}

function Tier2State({ hideImage, ...props }: Props) {
  const { stakingSymbol } = useStore($staking)
  const navigate = useNavigate()

  if (props.state !== 'tier2') return null

  return (
    <ProjectPageTier
      title={
        <>
          <span className="text-pink">Solarpunk</span> Tier
        </>
      }
      description={
        <div>
          {t('tier_update_description', {
            tokenAndSymbol: (
              <span className="text-pink">
                {props.moreTokens} {stakingSymbol}
              </span>
            ),
            betterChance: (
              <span className="text-pink">{props.betterChance}</span>
            ),
          })}
        </div>
      }
      tierImage={<Tier2 className={TIER_CN} />}
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function Tier3State({ hideImage, ...props }: Props) {
  const { stakingSymbol } = useStore($staking)
  const navigate = useNavigate()

  if (props.state !== 'tier3') return null

  return (
    <ProjectPageTier
      title={
        <>
          <span className="text-pink">Nanopunk</span> Tier
        </>
      }
      description={
        <div>
          {t('tier_update_description', {
            tokenAndSymbol: (
              <span className="text-pink">
                {props.moreTokens} {stakingSymbol}
              </span>
            ),
            betterChance: (
              <span className="text-pink">{props.betterChance}</span>
            ),
          })}
        </div>
      }
      tierImage={<Tier3 className={TIER_CN} />}
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function Tier4State({ hideImage, ...props }: Props) {
  const { stakingSymbol } = useStore($staking)
  const navigate = useNavigate()

  if (props.state !== 'tier4') return null

  return (
    <ProjectPageTier
      title={
        <>
          <span className="text-pink">Biopunk</span> Tier
        </>
      }
      description={
        <div>
          {t('tier_update_description', {
            tokenAndSymbol: (
              <span className="text-pink">
                {props.moreTokens} {stakingSymbol}
              </span>
            ),
            betterChance: (
              <span className="text-pink">{props.betterChance}</span>
            ),
          })}
        </div>
      }
      tierImage={<Tier4 className={TIER_CN} />}
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function Tier5State({ hideImage, ...props }: Props) {
  const navigate = useNavigate()
  const { stakingSymbol } = useStore($staking)

  if (props.state !== 'tier5') return null

  return (
    <ProjectPageTier
      title={
        <>
          <span className="text-pink">Cybernoir</span> Tier
        </>
      }
      description={
        <div>
          {t('tier_update_description', {
            tokenAndSymbol: (
              <span className="text-pink">
                {props.moreTokens} {stakingSymbol}
              </span>
            ),
            betterChance: (
              <span className="text-pink">{props.betterChance}</span>
            ),
          })}
        </div>
      }
      tierImage={<Tier5 className={TIER_CN} />}
      hideImage={hideImage}
      button={
        <Button
          type="green"
          className="mt-[1rem] text-[0.8rem] uppercase"
          onClick={() => navigate('/staking')}
        >
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

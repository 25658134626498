import Countdown, { zeroPad, CountdownRendererFn } from 'react-countdown'

interface Props {
  smallText?: boolean
  text?: string | null
  endTime?: string | Date | null
}

const renderer: (smallText: boolean) => CountdownRendererFn =
  (smallText: boolean) =>
  ({ days, hours, minutes, seconds }) => {
    const isLessThanOneMin = days === 0 && hours === 0 && minutes < 1
    return (
      <>
        {isLessThanOneMin
          ? '< 1 min'
          : `${zeroPad(days)}:${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
              seconds
            )}`}
      </>
    )
  }

export default function Timer({ text, endTime, smallText = false }: Props) {
  return (
    <div>
      {text && <div className="mb-[0rem] text-[0.8rem] text-gray">{text}</div>}
      <div className="font-codersCrux text-[3rem] leading-[85%] text-pink ml:text-[4rem]">
        {endTime ? (
          <Countdown
            zeroPadTime={2}
            date={endTime}
            renderer={renderer(smallText)}
          />
        ) : null}
      </div>
    </div>
  )
}

import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-right.svg'

export type Props = {
  items: TimelineItemProps[]
}

export default function ProjectPageTimeline({ items }: Props) {
  return (
    <div className="mb-[2.4rem] flex flex-col bg-graySecondary px-[1rem] py-[1rem] drop-shadow-blockShadow ml:flex-row ml:items-center ml:justify-between ml:py-0">
      {items.map((item) => {
        return <TimelineItem key={`timeline-item-${item.title}`} {...item} />
      })}
    </div>
  )
}

type TimelineItemProps = {
  title: string
  date: string
  isActive: boolean
  isLast: boolean
}

export function TimelineItem({
  title,
  date,
  isActive,
  isLast,
}: TimelineItemProps) {
  return (
    <div className="relative flex h-full flex-col justify-between last:mr-0 ml:mr-[2.1rem] ml:max-w-[33%] ml:grow ml:flex-row ml:items-center ml:py-[1rem]">
      {isActive && (
        <div className="absolute top-0 left-0 hidden h-[0.15rem] w-[85%] bg-greenLight ml:block"></div>
      )}
      <div>
        <div className="mb-[0.4rem] text-[0.9rem] font-bold">{title}</div>
        <div className="text-[0.7rem] text-gray">{date}</div>
      </div>
      {!isLast && (
        <ChevronIcon className="my-[1.2rem] h-[0.9rem] w-[0.9rem] rotate-90 ml:my-0 ml:rotate-0" />
      )}
    </div>
  )
}

import Decimal from 'decimal.js'
import { GeneralInfoQuery, GeneralInfoWithKycQuery } from 'gql'
import numeral from 'numeral'
import { toDecimal } from 'utils/numbers'

type StakingInfo = (GeneralInfoQuery | GeneralInfoWithKycQuery)['stakingInfo']

export class Staking {
  address: string
  apy: string
  leftToBeMined: string
  level: number
  minedPerDay: string
  ok: boolean
  rewardSymbol: string
  rewardAvailable: string
  rewardTotal: string
  rewardTokenTotalSupply: string
  stakingBalance: string
  stakingSymbol: string
  stakingTokenAddress: string
  stakingTokenDecimals: number
  tiers: StakingInfo['tiers']
  tierName: string
  totalStaked: string
  totalRewardToDistribute: string
  totalStakingSupply: string
  uniqueStakers: string
  unstakingFeeRatio: string
  walletBalance: string

  unstakingFeeRatioDec: Decimal
  allowanceDec: Decimal
  stakingBalanceDec: Decimal
  rewardAvailableDec: Decimal
  rewardTotalDec: Decimal
  walletBalanceDec: Decimal
  stakingWalletBalanceDec: Decimal

  constructor(walletBalanceDec: Decimal, stakingInfo?: StakingInfo) {
    this.ok = !!stakingInfo?.account

    this.walletBalanceDec = walletBalanceDec
    this.walletBalance = walletBalanceDec.toString()

    this.address = stakingInfo?.address ?? ''
    this.rewardSymbol = stakingInfo?.rewardToken?.symbol ?? ''
    this.stakingSymbol = stakingInfo?.stakingToken?.symbol ?? ''

    this.tiers =
      stakingInfo?.tiers.sort((a, b) => (a.level < b.level ? 1 : -1)) ?? []

    this.apy = !toDecimal(stakingInfo?.apy).eq(0)
      ? `${numeral(toDecimal(stakingInfo?.apy).mul(100).toString()).format(
          '0.00'
        )}%`
      : 'TBA'

    this.leftToBeMined = numeral(
      toDecimal(stakingInfo?.totalRewardToDistribute).sub(
        toDecimal(stakingInfo?.totalEmittedRewards)
      )
    ).format('0,0')

    this.totalStaked = numeral(stakingInfo?.totalStaked).format('0,0')

    this.totalRewardToDistribute = numeral(
      stakingInfo?.totalRewardToDistribute
    ).format('0,0')

    this.totalStakingSupply = numeral(
      stakingInfo?.stakingToken.totalSupply
    ).format('0,0')

    this.minedPerDay = !toDecimal(
      stakingInfo?.currentEpoch?.rewardsPerSecond
    ).eq(0)
      ? numeral(
          toDecimal(stakingInfo?.currentEpoch?.rewardsPerSecond)
            .mul(24 * 60 * 60)
            .toString()
        ).format('0,[.][0000]')
      : 'TBA'

    this.rewardAvailable = stakingInfo?.account?.rewardAvailable ?? '0'
    this.rewardTotal = stakingInfo?.account?.rewardTotal ?? '0'
    this.rewardTotalDec = toDecimal(stakingInfo?.account?.rewardTotal ?? '0')
    this.rewardTokenTotalSupply = numeral(
      stakingInfo?.rewardToken?.totalSupply ?? '0'
    ).format('0,00')

    this.rewardAvailableDec = toDecimal(
      stakingInfo?.account?.rewardAvailable ?? '0'
    )

    this.uniqueStakers = 'not in api' // todo

    this.stakingTokenAddress = stakingInfo?.stakingToken.address ?? ''
    this.stakingTokenDecimals = stakingInfo?.stakingToken.decimals ?? 0

    this.stakingBalanceDec = toDecimal(
      stakingInfo?.account?.stakingBalance ?? '0'
    )
    this.stakingBalance = this.stakingBalanceDec.toString()

    this.unstakingFeeRatio = toDecimal(stakingInfo?.unstakingFeeRatio ?? '0')
      .mul(100)
      .toString()

    this.unstakingFeeRatioDec = toDecimal(stakingInfo?.unstakingFeeRatio ?? '0')

    this.allowanceDec = toDecimal(stakingInfo?.account?.allowance ?? 0)
    // TODO ???
    // .minus(
    //   toDecimal(stakingInfo?.account?.stakingBalance ?? 0)
    // )

    this.stakingWalletBalanceDec = toDecimal(
      stakingInfo?.account?.walletBalance ?? 0
    )

    this.level = stakingInfo?.account?.tier?.level ?? 0
    this.tierName = stakingInfo?.account?.tier?.name ?? '-'
  }

  stakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)

    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.allowanceDec.lessThan(amountDecimal)) {
      return 'allowanceExceeded'
    } else if (this.walletBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToStake'
    } else if (input === '') {
      return 'waitingForInput'
    } else if (amountDecimal.gt(this.walletBalanceDec)) {
      return 'insufficientBalance'
    }
    return 'readyToStake'
  }

  unstakeTerminalState(input: string) {
    const amountDecimal = toDecimal(input)

    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.stakingBalanceDec.eq(0) || amountDecimal.eq(0)) {
      return 'notReadyToUnstake'
    } else if (input === '') {
      return 'waitingForInput'
    } else if (amountDecimal.gt(this.stakingBalanceDec)) {
      return 'insufficientBalance'
    }
    return 'readyToUnstake'
  }

  get claimTerminalState() {
    if (!this.ok) {
      return 'walletNotConnected'
    } else if (this.rewardAvailableDec.eq(0)) {
      return 'notReadyToClaim'
    }
    return 'readyToClaim'
  }
}

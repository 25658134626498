import { ReactNode } from 'react'
import { ReactComponent as TierBlockDecor } from 'resources/images/tier-block-decor.svg'

export interface Props {
  title: string | ReactNode
  description?: string | ReactNode
  tierImage?: ReactNode
  hideImage?: boolean
  button?: ReactNode
  hide?: boolean
}

export default function ProjectPageTier({ hide = false, ...props }: Props) {
  if (hide) return null

  return (
    <div className="relative z-10 col-span-2 flex flex-col justify-end bg-graySecondary p-[1rem] drop-shadow-blockShadow ml:col-span-1 ml:min-h-[16.5rem]">
      <TierBlockDecor className="absolute left-0 top-[-0.79rem] z-0 h-[0.8rem] w-[1.6rem]" />

      {!props.hideImage && (
        <div className="absolute top-[0rem] left-0 hidden h-full w-full overflow-hidden ml:block dxs:top-[-1rem] dxs:top-0 dxs:overflow-visible">
          {props.tierImage}
        </div>
      )}
      <div className="relative z-10">
        <div className="mb-[0.4rem] text-[1.2rem] font-light">
          {props.title}
        </div>
        {props.description && (
          <div className="max-w-full text-[0.8rem] ml:max-w-[13rem] t:max-w-[17rem]">
            {props.description}
          </div>
        )}
        {props.button}
      </div>
    </div>
  )
}

import { sample } from 'effector'
import {
  $stakingTokenWalletBalanceDec,
  fetchStakingTokenWalletBalanceFx,
  stakingTick,
  WalletBalanceGate,
} from '.'

sample({
  source: fetchStakingTokenWalletBalanceFx.doneData,
  target: $stakingTokenWalletBalanceDec,
})

setTimeout(fetchStakingTokenWalletBalanceFx, 1000)
setInterval(stakingTick, 10 * 1000)

sample({
  clock: stakingTick,
  source: WalletBalanceGate.status,
  filter: Boolean,
  target: fetchStakingTokenWalletBalanceFx,
})

import isEmpty from 'lodash/isEmpty'
import { ReactComponent as UnlockedIcon } from 'resources/images/icons/unlocked.svg'
import { ReactComponent as LockedIcon } from 'resources/images/icons/locked.svg'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-up.svg'
import { useCallback, useState } from 'react'
import Button from './Button'
import t from 'utils/t'

export type Props = {
  checkpoints?: {
    unlockDate: string
    unlockPercent: string
    totalAmount: string
    yourClaim?: string
    yourClaimComputed: string
    status: 'Locked' | 'Unlocked' | 'Claimed'
  }[]
  claimTicker: string
  showClaimButton: boolean
}

export default function ProjectPageVesting({
  checkpoints,
  terminalRef,
  showClaimButton,
  claimTicker,
}: Props & { terminalRef: any }) {
  const [vestingIsOpen, setVestingIsOpen] = useState(false)

  const handleScrollToTerminalButtonClick = useCallback(() => {
    if (terminalRef && terminalRef.current) {
      terminalRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [terminalRef])

  if (isEmpty(checkpoints)) {
    return null
  }

  const checkpointsCount = checkpoints?.length

  const disabledClaimButton = checkpoints?.some(
    (checkpoint) => checkpoint.status !== 'Unlocked'
  )

  return (
    <div className="drop-shadow-blockShadow">
      <div className="mb-[2.4rem] overflow-hidden border border-gray9A bg-black px-[1rem]">
        <div
          className="group flex cursor-pointer items-center justify-between py-[1rem] text-[1.2rem] font-light text-grayLight"
          onClick={() => setVestingIsOpen(!vestingIsOpen)}
        >
          <div>Vesting Schedule</div>
          <div className="bg-pink bg-opacity-15 p-[0.5rem] transition-colors group-hover:bg-opacity-30">
            <ChevronIcon
              className={`
              h-[0.9rem] w-[0.9rem] transition-transform
              ${vestingIsOpen ? 'rotate-180' : 'rotate-0'}
           `}
            />
          </div>
        </div>
        <div
          className={`${
            vestingIsOpen
              ? 'flex flex-col-reverse ml:flex-col  dxs:min-h-full'
              : 'max-h-0 min-h-0'
          } `}
        >
          <div className={`${vestingIsOpen ? 'mb-[1rem]' : ''}`}>
            {checkpoints?.map((checkpoint, i) => {
              return (
                <div
                  key={`vesting-checkpoint-${i}`}
                  className={`
                  relative mb-[0.6rem] grid items-center justify-between border bg-opacity-15 p-[0.6rem] pr-[0.7rem] last:mb-0 
                  ml:grid-cols-[1fr,1fr,1fr,1fr,1fr,0.7rem] 
                  dxs:grid-cols-[4rem,14rem,1fr,1fr,1fr,1fr,0.7rem]
                  ${
                    checkpoint.status === 'Claimed'
                      ? 'border-grayDA bg-grayDA'
                      : ''
                  }
                  ${
                    checkpoint.status === 'Unlocked'
                      ? 'vesting-unlock-shadow border-pink bg-pink'
                      : ''
                  }
                  ${checkpoint.status === 'Locked' ? 'border-gray9A' : ''}
                `}
                >
                  <div className="mb-[0.6rem] text-[0.7rem] ml:mb-0 ml:hidden dxs:block">
                    <span
                      className={`font-medium ${
                        checkpoint.status === 'Unlocked'
                          ? 'text-pink'
                          : 'text-grayLight'
                      }`}
                    >
                      {i + 1}
                    </span>
                    <span
                      className={`${
                        checkpoint.status === 'Unlocked'
                          ? 'text-pinkActive'
                          : 'text-gray'
                      }`}
                    >
                      /{checkpointsCount}
                    </span>
                  </div>
                  <Field
                    title={t('date')}
                    value={checkpoint.unlockDate}
                    status={checkpoint.status}
                  />
                  <Field
                    title={t('percentage')}
                    value={checkpoint.unlockPercent}
                    status={checkpoint.status}
                  />
                  <Field
                    title={t('total_unlock')}
                    value={checkpoint.totalAmount}
                    status={checkpoint.status}
                  />
                  <Field
                    title={t('your_claim')}
                    value={checkpoint.yourClaimComputed}
                    status={checkpoint.status}
                  />
                  <Field
                    title={t('status')}
                    value={checkpoint.status}
                    status={checkpoint.status}
                  />
                  {checkpoint.status === 'Claimed' ||
                  checkpoint.status === 'Unlocked' ? (
                    <UnlockedIcon
                      className={`
                  absolute top-[1rem] right-[1rem] h-[0.8rem]  w-[0.7rem] ml:static 
                  ${checkpoint.status === 'Claimed' ? 'fill-grayLight' : ''}
                  ${checkpoint.status === 'Unlocked' ? 'fill-pink' : ''}
                `}
                    />
                  ) : (
                    <LockedIcon className="absolute top-[0.6rem] right-[0.6rem] h-[0.8rem] w-[0.7rem] fill-grayLight ml:static" />
                  )}
                </div>
              )
            })}
          </div>
          {showClaimButton && (
            <div className="mb-[1rem] flex items-center justify-end">
              <Button
                disabled={disabledClaimButton}
                className="w-full text-[0.8rem] font-medium uppercase ml:w-auto"
                onClick={handleScrollToTerminalButtonClick}
              >
                {t('vesting_claim_button_text', {
                  ticker: claimTicker,
                })}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

type FieldProps = {
  title: string
  value: string
  status: string
}
function Field({ title, value, status }: FieldProps) {
  return (
    <div className="mb-[0.6rem] flex text-[0.7rem] ml:mb-0 ml:flex-col">
      <div
        className={`w-[5rem] flex-shrink-0 ${
          status === 'Unlocked' ? 'text-pinkActive' : 'text-gray'
        }`}
      >
        {title}
      </div>
      <div
        className={`font-medium ${
          status === 'Unlocked' ? 'text-pink' : 'text-grayLight'
        }`}
      >
        {value}
      </div>
    </div>
  )
}

import { sample } from 'effector'

import {
  $registerModalStatus,
  registerFx,
  RegisterModalStatus,
  setRegisterModalStatus,
} from '.'

sample({
  clock: setRegisterModalStatus,
  fn: (status: RegisterModalStatus): RegisterModalStatus => status,
  target: $registerModalStatus,
})

sample({
  clock: registerFx,
  fn: (): RegisterModalStatus => 'pending',
  target: $registerModalStatus,
})

sample({
  clock: registerFx.doneData,
  fn: ({ registerPoolParticipation: { success } }): RegisterModalStatus =>
    success ? 'registered' : 'failed',
  target: $registerModalStatus,
})

sample({
  clock: registerFx.failData,
  fn: (): RegisterModalStatus => 'failed',
  target: $registerModalStatus,
})

import Modal from 'components/dumb/Modal'
import { useStore } from 'effector-react'
import { $showAboutKyc, $showWallet, $wallet, closeWallet } from 'models/wallet'
import { ReactNode } from 'react'
import AboutKyc from './AboutKyc'
import WalletConnected from './WalletConnected'
import WalletList from './WalletList'

export default function Wallet() {
  const wallet = useStore($wallet)
  const showWallet = useStore($showWallet)
  const showAboutKyc = useStore($showAboutKyc)

  let content: ReactNode = null

  switch (wallet?.state) {
    case 'connected':
      content = showAboutKyc ? <AboutKyc /> : <WalletConnected />
      break
    case 'connecting':
    case 'notConnected':
    case 'error':
    default:
      content = <WalletList />
  }

  return (
    <Modal show={showWallet} onClose={closeWallet}>
      {content}
    </Modal>
  )
}

import ProjectPageFieldsMaster, {
  Props as FieldsProps,
} from 'components/collections/ProjectPageFieldsMaster'
import { isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'
import t from 'utils/t'

export interface Props {
  name: string
  logo?: string | null
  subtitle: string
  description: string
  incubated: boolean
  label: string
  tags: string[]
  dueDiligenceReport: string
  website: string
  fieldsProps: FieldsProps
}

export default function ProjectPageHeading(props: Props) {
  return (
    <>
      <ReactTooltip
        className="react-tooltip-custom"
        id="private-public-tooltip"
      />
      <div className="grid items-center gap-[1.4rem] dxs:grid-cols-2">
        <div>
          {props.logo && (
            <div className="p-[0.4rem]">
              <img
                className="mb-[1.2rem] h-[5.5rem]"
                src={props.logo}
                alt={`${props.name} logo`}
              />
            </div>
          )}
          <div className="mb-[0.3rem] text-ellipsis font-pressStart2P text-[1.2rem] leading-[120%] text-pink ml:text-[1.8rem]">
            {props.name}
          </div>
          <div className="mb-[0.3rem] text-[0.9rem] font-medium leading-[140%] text-white">
            {props.subtitle}
          </div>
          <div className="mb-[0.3rem] text-[0.8rem] text-gray">
            {props.description}
          </div>
          <div className="items-center pt-[0.8rem] font-codersCrux text-pink mm:flex">
            <div
              data-tip={t('ido_type_tooltip_text')}
              data-for="private-public-tooltip"
              className="mb-[0.5rem] inline-block cursor-default border border-pink px-[0.8rem] text-[1.1rem] uppercase mm:mb-0 mm:block"
            >
              {props.label}
            </div>
            <div className="flex flex-wrap">
              {props.tags.map((tag) => (
                <div
                  key={`tag-${tag}`}
                  className=" mr-[0.8rem] text-[1.1rem] first:ml-0 mm:first:ml-[0.8rem]"
                >
                  #{tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="gap-x grid grid-cols-1 gap-y-[1.4rem] gap-x-[1rem] ml:grid-cols-2 ml:grid-rows-3">
          <ProjectPageFieldsMaster {...props.fieldsProps} />
          {props.incubated && (
            <div className="relative flex h-[3.6rem] flex-col items-end overflow-hidden border border-white bg-black py-[0.6rem] px-[1rem] text-greenLight drop-shadow-blockShadow">
              <div className="absolute left-0 bottom-0 h-[3.6rem] w-[3.6rem] bg-aquarium bg-cover bg-no-repeat ml:h-[3.2rem] ml:w-[3.2rem]" />
              <span className="text-[0.8rem]">
                {t('incubated_by_launchpad')}
              </span>
              <a
                href={props.website}
                target="__blank"
                className="cursor-pointer text-[0.7rem] underline hover:no-underline"
              >
                {t('read_more')}
              </a>
            </div>
          )}
          {!isEmpty(props.dueDiligenceReport) && (
            <div className="relative flex h-[3.6rem] flex-col items-end overflow-hidden bg-greenLight py-[0.6rem] px-[1rem] drop-shadow-blockShadow ">
              <div className="absolute left-0 bottom-0 h-[3.6rem] w-[3.6rem] bg-fish bg-cover bg-no-repeat ml:h-[3.2rem] ml:w-[3.2rem]" />
              <span className="text-[0.8rem]">{t('due_diligence_report')}</span>
              <a
                className="mt-[0.1rem] bg-black px-[0.2rem] text-[0.7rem] text-greenLight"
                href={props.dueDiligenceReport}
                target="__blank"
              >
                {t('open_pdf')}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

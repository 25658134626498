import Button from 'components/dumb/Button'
import Dropdown from 'components/dumb/Dropdown'
import { ReactComponent as LockIcon } from 'resources/images/icons/lock.svg'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import { openUrl } from 'utils'
import { formatNumber } from 'utils/numbers'
import t from 'utils/t'
import { useStore } from 'effector-react'
import {
  fetchIndexPoolInfoFx,
  $indexPoolInfo,
  $assets,
} from 'models/indexPoolInfo'
import { useEffect } from 'react'
import { $staking } from 'models/staking'
import { Asset } from 'gql'

export default function SmartDashboard() {
  const { rewardSymbol, stakingSymbol /*, rewardTokenTotalSupply */ } =
    useStore($staking)

  const navigate = useNavigate()
  const indexPoolInfo = useStore($indexPoolInfo)

  const assets = useStore($assets)
  useEffect(() => {
    fetchIndexPoolInfoFx()
    const intervalId = setInterval(fetchIndexPoolInfoFx, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className="mt-[40px] mb-[180px] t:mt-[100px]">
      <div className="rounded-[8px] border border-balticSea bg-cinder/90 p-6">
        <div className="grid grid-cols-1 gap-x-9 gap-y-9 t:grid-cols-2 dxs:grid-cols-[1.5fr,1fr,1.5fr]">
          <div>
            <div className="mb-4 text-[1.2rem] font-light text-grayTertiary">
              ${rewardSymbol} pool total worth
            </div>
            <div className="relative mb-5 flex items-end space-x-2 font-pressStart2P text-[34px] leading-[1.2] text-grayLight">
              <div>{numeral(indexPoolInfo?.totalValueUSD).format('0,00')}</div>
              <div className="relative bottom-[3px] text-[0.9rem] font-light">
                USD
              </div>
            </div>
            <span className="border-primary rounded-[3px] border px-3 py-2 font-codersCrux text-[1.2rem] leading-[1.2] text-pink">
              {assets?.length} assets in the pool
            </span>
          </div>
          <div className="grid-flow-cols-1 grid gap-5 text-grayTertiary ml:grid-cols-2 t:grid-cols-1">
            <div>
              <div className="mb-1 text-[0.8rem] leading-[150%]">
                ${rewardSymbol} est. price
              </div>
              <div className="relative flex items-end space-x-1 font-pressStart2P leading-5 text-greenLight">
                <div className="text-[0.9rem]">
                  {numeral(indexPoolInfo?.indexTokenFairPriceUSD).format(
                    '0,0.00[0000]'
                  )}
                </div>
                <div className="relative bottom-[-2px] text-[0.6rem]">USD</div>
              </div>
            </div>
            <div>
              <div className="mb-1 text-[0.8rem] leading-[150%]">
                ${rewardSymbol} token total supply
              </div>
              <div className="relative flex items-end space-x-1 font-pressStart2P leading-5 text-greenLight">
                <div className="text-[0.9rem]">
                  {numeral(/*rewardTokenTotalSupply*/ 100_000_000).format(
                    '0,0'
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex rounded-[8px] border border-balticSea 
            bg-mineShaft bg-contain bg-no-repeat t:col-span-full dxs:col-span-1 dxs:min-h-[172px] dxs:bg-dashboardPic"
          >
            <div
              className="
              flex
	      h-full w-full flex-col justify-between px-6 py-6 t:flex-row
	      dxs:w-[248px] dxs:flex-col dxs:py-4
	      "
            >
              <div className="mb-6 text-[1.2rem] font-light leading-[120%] text-grayLight t:mb-0 t:w-full dxs:w-[80%] ds:w-full">
                Stake <span className="font-medium">${stakingSymbol}</span> to
                Earn <span className="font-medium">${rewardSymbol}</span>
              </div>

              <Button onClick={() => navigate('/staking')}>
                {t('go_to_staking')}
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-geyser/40 my-5 h-[1px] w-full" />
        <div className="grid min-h-[280px] grid-cols-1 gap-x-3 gap-y-6 ml:grid-cols-2 dxs:grid-cols-3 dl:grid-cols-4">
          {assets?.map((t) => (
            <AssetCard key={t?.projectName} {...t} />
          ))}
        </div>
      </div>

      <div className="mt-[48px] mb-4 text-[1.2rem] font-light text-grayLight">
        ${rewardSymbol} Utility{' '}
      </div>
      <div className="grid grid-cols-1 gap-5 dxs:grid-cols-3">
        <div className="rounded-[8px] border border-pink bg-codGray p-6">
          <div className="mb-[0.4rem] font-manrope text-[1.6rem] font-light leading-[130%] text-grayLight">
            HODL
          </div>
          <p className="text-[0.9rem] font-light leading-[130%] text-boulder">
            <span className="font-medium">
              ${rewardSymbol} grants its holders exposure to the Index Pool
            </span>
            , a weighted-basket of tokens representing the value of projects
            launched on the OccamRazer platform.
          </p>
        </div>
        <div className="flex flex-col justify-between rounded-[8px] border border-balticSea bg-codGray p-6">
          <div>
            <div className="mb-[0.4rem] font-manrope text-[1.6rem] font-light leading-[130%] text-grayLight">
              TRADE
            </div>
            <p className="text-[0.9rem] font-light leading-[130%] text-boulder">
              {t('dashboard_trade', { rewardSymbol })}
            </p>
          </div>
          <Dropdown items={[]} className="w-fit" disabled>
            <span className="normal-case">Trade</span> {rewardSymbol}
          </Dropdown>
        </div>
        <div className="flex flex-col justify-between rounded-[8px] border border-balticSea bg-codGray p-6">
          <div className="relative flex items-center">
            <div className="mb-[0.4rem] font-manrope text-[1.6rem] font-light leading-[130%] text-grayLight">
              BURN
            </div>
            <div className="relative bottom-[4px] ml-2 rounded border border-boulder  py-[2px] px-[10px] text-[0.7rem] text-boulder">
              Coming Soon
            </div>
          </div>
          <p className="mb-4 text-[0.9rem] font-light leading-[130%] text-boulder">
            {t('dashboard_burn', { rewardSymbol })}
          </p>
          <Button
            disabled
            className="flex w-auto items-center space-x-2 self-start"
          >
            <LockIcon className="text-quillGray relative bottom-[1px] h-4 w-4" />
            <div>
              <span className="normal-case">Burn</span> ${rewardSymbol}
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

function AssetCard({
  amount,
  logoLink,
  priceUSD,
  projectName,
  symbol,
  valueUSD,
  websiteLink,
}: Asset) {
  const handleClick = () => {
    openUrl(websiteLink)
  }
  const amount_ = isNaN(Number(amount))
    ? amount
    : `${formatNumber(amount)} ${symbol}`

  const usd_ = isNaN(Number(valueUSD))
    ? valueUSD
    : `${formatNumber(valueUSD)} USD`

  return (
    <div
      className="
      min-h-[128px] cursor-pointer rounded-[5px] border border-white/6 p-[0.6rem]
      hover:border-pink hover:bg-pink/10"
      onClick={handleClick}
    >
      <div className="flex space-x-3">
        <div className="relative h-[2.15rem] w-[2.15rem] rounded-[0.2rem] bg-white/8 p-[0.25rem]">
          <div className="relative flex h-full w-full items-center justify-center ">
            <img alt="" src={logoLink} />
          </div>
        </div>
        <div className="text-[0.9rem] font-light leading-[1.4] text-grayLight">
          <div>{projectName}</div>
          <div className="font-medium">${symbol}</div>
        </div>
      </div>
      <div className="mt-2 flex justify-between space-x-4 leading-[1.2] ml:justify-start">
        <div>
          <div className="text-[0.7rem] text-gray">Amount</div>
          <div className="text-[0.8rem] text-grayLight">
            {amount === '0' ? 'To be announced' : amount_}
          </div>
        </div>
        <div>
          <div className="text-[0.7rem] text-gray">Value</div>
          <div className="text-[0.8rem] font-medium text-grayLight">
            {valueUSD === '0' ? 'To be announced' : usd_}
          </div>
        </div>
      </div>
    </div>
  )
}

import Header from 'components/dumb/Header'
import NavLink from 'components/dumb/NavLink'
import { RouteObject } from 'react-router-dom'
import SmartDebugButton from './SmartDebugButton'
import SmartWalletButton from './SmartWalletButton'

type Props = {
  routes: RouteObject[]
}

export default function SmartHeader({ routes }: Props) {
  const headerNavLinks = [
    { to: '/', label: 'IDOs' },
    { to: '/staking', label: 'Staking' },
    { to: '/dashboard', label: 'Dashboard' },
  ].map(({ to, label }) => (
    <NavLink
      key={to}
      to={to}
      className="inline-flex items-center border-b-2 px-4 pt-[16px] pb-[11px] font-codersCrux text-[24px] leading-none antialiased hover:text-pinkHover active:text-pinkActive"
      activeClassName="!text-black border-blackSecondary"
      inactiveClassName="text-black/25 border-transparent"
      routes={routes}
    >
      {label}
    </NavLink>
  ))

  return (
    <Header>
      <div className="flex justify-center dxs:justify-start">
        {headerNavLinks}
      </div>
      {/* FYI: кнопка дебага влияет на отображение картинок на главной, когда ее уберем все будет ок */}
      <SmartDebugButton debug={process.env.REACT_APP_DEBUG === 'true'} />
      <SmartWalletButton />
    </Header>
  )
}

import { sample } from 'effector'
import { $session } from 'models/sessions'
import {
  $generalInfo,
  $kycInfo,
  fetchGeneralInfo,
  fetchGeneralInfoFx,
  fetchGeneralInfoWithKYCFx,
} from '.'

sample({
  clock: fetchGeneralInfo,
  source: $session,
  filter: (session) => session.length === 0,
  target: fetchGeneralInfoFx,
})

sample({
  clock: fetchGeneralInfo,
  source: $session,
  filter: (session) => session.length > 0,
  target: fetchGeneralInfoWithKYCFx,
})

// Если данные пришли, сохраняем
sample({
  clock: fetchGeneralInfoFx.doneData,
  target: $generalInfo,
})

sample({
  clock: fetchGeneralInfoWithKYCFx.doneData,
  target: $generalInfo,
})

sample({
  clock: fetchGeneralInfoWithKYCFx.doneData,
  fn: (data) => data.kycInfo,
  target: $kycInfo,
})

// Если сессия пользователя изменилась, грузим для неё актуальные данные
sample({
  clock: $session,
  target: fetchGeneralInfoFx,
})

// При старте приложения пробуем загрузить данные
fetchGeneralInfo()

// Отправляем запросы каждые 5 секунд
setInterval(fetchGeneralInfo, 5000)

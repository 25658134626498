import GhostButton from 'components/dumb/GhostButton'
import TextInput from 'components/dumb/TextInput'
import { noop } from 'utils'

interface CurrencyInputPanelProps {
  className?: string
  symbol?: string
  value: string
  label?: string
  onUserInput?(value: string): void
  leftCount?: string
  leftSub?: string
  rightSub?: string
  rightCount?: string
  onMax?(): void
  currency?: string | null
  disabled?: boolean
  showMaxButton?: boolean
  decimals?: number
}

export default function CurrencyInputPanel({
  className = '',
  symbol,
  label = 'You send',
  value,
  leftSub,
  leftCount,
  rightSub,
  rightCount,
  decimals,
  onUserInput = noop,
  onMax = noop,
  disabled = false,
  showMaxButton = false,
}: CurrencyInputPanelProps) {
  return (
    <div className={className}>
      <div>
        <div className="w-full grow dxs:w-auto">
          <div className="mb-[0.4rem] flex items-center justify-between font-manrope text-[0.7rem] font-medium ">
            <span className="text-blackSecondary">{label}</span>
            {rightSub && (
              <span className="text-pink">
                {rightSub}: {rightCount}
              </span>
            )}
          </div>
          <div
            className={`
              relative
              flex items-center
              justify-between bg-grayLight px-[0.6rem] font-manrope
              text-[0.8rem] leading-none
              ${showMaxButton ? 'py-[0.6rem]' : 'py-[0.75rem]'}
            `}
          >
            <TextInput
              value={value}
              placeholder="0.00"
              className={`
                  peer relative z-10 w-full flex-grow bg-transparent text-left  placeholder-grayTertiary focus:outline-none
                  ${disabled ? 'text-gray' : 'text-black'}
                `}
              onChange={onUserInput}
              disabled={disabled}
              decimals={decimals}
            />
            <div
              className={`
                absolute top-0
                left-0 z-0 h-full w-full border border-grayDA 
                peer-hover:border-gray9A peer-focus:border-gray9A
              `}
            ></div>

            {showMaxButton ? (
              <GhostButton onClick={onMax} className="relative z-10 ">
                MAX
              </GhostButton>
            ) : null}
            <span className="relative z-10  ml-[0.4rem] text-[0.8rem] leading-none text-grayTertiary">
              {symbol}
            </span>
          </div>
        </div>
      </div>
      {leftSub && (
        <div
          className={`font-mono mt-2 mb-[0.8rem] flex justify-between text-base`}
        >
          <div className="text-[0.7rem] leading-[140%] text-grayTertiary">
            <p>
              {leftSub}: {leftCount}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

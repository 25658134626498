import classNames from 'classnames'
import { noop, tw } from 'utils'

interface TabProps {
  children?: React.ReactNode
  current?: boolean
  disabled?: boolean
  onClick?(): void
}

export default function StakingPageTerminalTab({
  children,
  onClick = noop,
  current = false,
  disabled = false,
}: TabProps) {
  const base = `
border border-transparent leading-[150%]outline-none p-[0.3rem] font-pressStart2P text-[0.5rem] text-gray
hover:!border-gray hover:bg-graySecondary hover:text-gray hover:tab-shadow
disabled:text-grayTertiary disabled:border-none disabled:bg-transparent disabled:shadow-none
`
  const active = tw`!border-black text-blackSecondary bg-graySecondary tab-shadow `

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(base, {
        [active]: current,
      })}
    >
      {children}
    </button>
  )
}

import { Parser } from 'commonmark'
import { memo, ReactNode, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Element, Link } from 'react-scroll'
import Sticky from 'react-sticky-el'
import rehypeRaw from 'rehype-raw'
import uslug from 'uslug'
import type { Options } from './rehype-section'
import rehypeSection from './rehype-section'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-up.svg'
import t from 'utils/t'

interface Props {
  markdown: string
}

function parseTOC(markdown: string): string[] {
  const toc: string[] = []

  const parser = new Parser()
  const ast = parser.parse(markdown)

  const walker = ast.walker()
  let inHeading = false
  let headingText = ''

  for (let event = walker.next(); event != null; event = walker.next()) {
    const node = event.node
    if (node.type === 'heading' && node.level === 2) {
      if (event.entering) {
        inHeading = true
        headingText = ''
        continue
      }

      inHeading = false
      toc.push(headingText)
    } else if (inHeading && node.type === 'text') {
      headingText += node.literal
    }
  }

  return toc
}

const DetailsInformation = ({ markdown }: Props) => {
  const toc = parseTOC(markdown)

  const [detailsIsOpen, setDetailsIsOpen] = useState(false)

  return (
    <div className="dxs:mb-[8rem]">
      <div
        className="group flex cursor-pointer items-center justify-between border-b border-grayLight py-[1rem] text-[1.2rem] font-light text-grayLight"
        onClick={() => setDetailsIsOpen(!detailsIsOpen)}
      >
        <div>{t('project_details')}</div>
        <div className="bg-pink bg-opacity-15 p-[0.5rem] transition-colors group-hover:bg-opacity-30">
          <ChevronIcon
            className={`
              h-[0.9rem] w-[0.9rem] transition-transform
              ${detailsIsOpen ? 'rotate-180' : 'rotate-0'}
           `}
          />
        </div>
      </div>
      <div
        className={`
          ${detailsIsOpen ? 'dxs:min-h-[100vh]' : 'max-h-0 min-h-0'}
          grid-cols-[18rem,1fr] overflow-hidden text-grayLight transition-all duration-500 dxs:grid
        `}
      >
        <div className={`relative hidden h-full dxs:block`}>
          <Sticky>
            <div>
              <div className="sticky pt-[2rem]">
                {toc.map((title, i) => {
                  return (
                    <div key={`menu-details-${i}`}>
                      <Link
                        className={`
                          relative flex h-[2rem] cursor-pointer items-center border-l-[0.15rem] border-pink border-opacity-0 pl-[0.6rem]
                          text-[0.8rem] font-medium text-gray transition-all duration-300 
                        `}
                        activeClass="!text-pink border-opacity-100"
                        to={uslug(title)}
                        spy={true}
                        smooth={true}
                        duration={400}
                        offset={-45}
                      >
                        {title}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </Sticky>
        </div>

        <div className={`relative pt-[2rem] dxs:pb-[8rem]`}>
          <div className="markdown">
            <ReactMarkdown
              rehypePlugins={[
                [rehypeSection, { slugger: uslug } as Options],
                rehypeRaw,
              ]}
              components={{
                h2: ({ children }) => (
                  <div className="mb-[0.8rem] font-pressStart2P text-[0.8rem]">
                    {children}
                  </div>
                ),
                section: ({ node, children }) => {
                  const slug = node.properties?.slug?.toString() ?? null
                  if (!slug) return null

                  return (
                    <Element
                      className="mb-[2rem] last:dxs:min-h-[70vh]"
                      name={slug}
                    >
                      {children}
                    </Element>
                  )
                },
              }}
            >
              {markdown}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(DetailsInformation)

const getNodeText = (node: ReactNode): string => {
  if (typeof node === 'string') return node
  if (typeof node === 'number') return node.toString()
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node && 'props' in node)
    // eslint-disable-next-line
    return getNodeText(node.props.children)

  return ''
}

import StakingPageTiers from 'components/dumb/StakingPageTiers'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import SmartStakingStats from './SmartStakingStats'
import SmartStakingTerminal from './SmartStakingTerminal'

export default function SmartStaking() {
  const staking = useStore($staking)

  document.title = 'NEARStarter | Staking'

  return (
    <div className="relative mt-8 mb-[5rem] dxs:mt-[4rem]">
      <div className="relative z-20 mb-[3rem] dxs:mb-[1.5rem]">
        <div className="grid grid-cols-1 gap-y-8 gap-x-4 dxs:grid-cols-2">
          <SmartStakingStats />
          <SmartStakingTerminal />
        </div>
      </div>
      <div className="relative z-10 h-[7.5rem] w-full">
        <div className="absolute -bottom-[10rem] w-full">
          <StakingPageTiers
            tiers={staking.tiers}
            stakingSymbol={staking.stakingSymbol}
          />
        </div>
      </div>
    </div>
  )
}

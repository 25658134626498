import Button from 'components/dumb/Button'
import { useStore } from 'effector-react'
import { $session } from 'models/sessions'
import { $networkIsOk, $shortAddress, openWallet } from 'models/wallet'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { ReactComponent as SwitchIcon } from 'resources/images/icons/switch.svg'
import t from 'utils/t'

type Props = { className?: string }

export default function SmartWalletButton({ className }: Props) {
  const shortAddress = useStore($shortAddress)
  const session = useStore($session)
  const networkIsOk = useStore($networkIsOk)

  let state: 'notConnected' | 'connected' | 'connectedToWrongNetwork'
  if (session === '') {
    state = 'notConnected'
  } else if (!networkIsOk) {
    state = 'connectedToWrongNetwork'
  } else {
    state = 'connected'
  }

  let content: ReactNode = null
  switch (state) {
    case 'connected':
      content = shortAddress
      break
    case 'connectedToWrongNetwork':
      content = (
        <div className="inline-flex items-center space-x-2 text-grayLight">
          <SwitchIcon className="h-3 w-3" />
          <div>{t('switch_network')}</div>
        </div>
      )
      break
    case 'notConnected':
    default:
      content = t('connect_wallet')
      break
  }

  return (
    <Button
      className={`
        ${className} 
        relative font-manrope text-[0.8rem]  dxs:-top-1 
        ${state === 'connected' ? 'lowercase' : 'uppercase'}
      `}
      onClick={openWallet}
      type={state === 'connectedToWrongNetwork' ? 'switch' : 'connect'}
    >
      {content}
    </Button>
  )
}

export const noop = () => {}
export const tw = (classNames: TemplateStringsArray) => `${classNames}`
export const openUrl = (url?: string) => {
  if (!url) return
  window.open(url, '_blank', 'noopener,noreferrer')
}
export const shortenAddress = (address: string) =>
  address.length > 4
    ? `${address.slice(0, 4)}...${address.slice(
        address.length - 4,
        address.length
      )}`
    : ''

const isMainnet = process.env.REACT_APP_AURORA_NETWORK === 'mainnet'

export const desiredNetwork =
  process.env.REACT_APP_AURORA_NETWORK === 'mainnet' ? 'mainnet' : 'testnet'

// source: https://doc.aurora.dev/getting-started/network-endpoints/
export const desiredChainId =
  desiredNetwork === 'mainnet' ? '0x4e454152' : '0x4e454153'

export const desiredChain =
  desiredNetwork === 'mainnet'
    ? {
        chainId: '0x4e454152',
        chainName: 'Aurora',
        nativeCurrency: {
          name: 'Aurora',
          symbol: 'AURORA',
          decimals: 18,
        },
        blockExplorerUrls: ['https://aurorascan.dev'],
        rpcUrls: ['https://mainnet.aurora.dev'],
      }
    : {
        chainId: '0x4e454153',
        chainName: 'Aurora Testnet',
        nativeCurrency: {
          name: 'Aurora',
          symbol: 'AURORA',
          decimals: 18,
        },
        blockExplorerUrls: ['https://testnet.aurorascan.dev'],
        rpcUrls: ['https://testnet.aurora.dev'],
      }

export const getAddressExplorerUrl = (address: string) =>
  isMainnet
    ? `https://aurorascan.dev/address/${address}`
    : `https://${desiredNetwork}.aurorascan.dev/address/${address}`

export const getTxExplorerUrl = (hash: string) =>
  isMainnet
    ? `https://aurorascan.dev/tx/${hash}`
    : `https://${desiredNetwork}.aurorascan.dev/tx/${hash}`

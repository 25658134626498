import ModalHeader from 'components/dumb/ModalHeader'
import { useStore } from 'effector-react'
import { $address, $wallet, closeWallet, logout } from 'models/wallet'
import { getWalletIconById, getWalletNameById } from 'models/wallet/wallets'
import { ReactComponent as LinkIcon } from 'resources/images/icons/external-link.svg'
import { ReactComponent as LogoutIcon } from 'resources/images/icons/logout.svg'
import { getAddressExplorerUrl, openUrl, shortenAddress } from 'utils'
import t from 'utils/t'
import WalletKyc from './WalletKyc'
import WalletTier from './WalletTier'

export default function WalletConnected() {
  const wallet = useStore($wallet)
  const address = useStore($address)

  const showKyc = process.env.REACT_APP_SHOW_KYC === 'true'

  if (!wallet) {
    return null
  }

  const name = getWalletNameById(wallet.id)
  const icon = getWalletIconById(wallet.id)

  return (
    <div>
      <ModalHeader label={t('connected_wallet')} onRightClick={closeWallet} />
      <div className="w-full">
        <div className="flex flex-1 items-center border border-black/25">
          <div className="flex flex-1 cursor-default items-center justify-between p-4">
            <button
              className="flex cursor-pointer items-center"
              onClick={() => openUrl(getAddressExplorerUrl(address))}
            >
              <p className="font-manrope font-light text-pink">
                {shortenAddress(address)}
              </p>
              <LinkIcon className="ml-[0.15rem] h-[0.55rem] w-[0.55rem]" />
            </button>
            <div className="flex items-center space-x-4">
              <LogoutIcon className="cursor-pointer" onClick={() => logout()} />
              <img src={icon} alt={name} className="h-[1.2rem] w-[1.2rem]" />
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-1 flex-col border border-black/25 p-4">
          <WalletTier />
          {showKyc && <WalletKyc />}
        </div>
      </div>
    </div>
  )
}

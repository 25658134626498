import { attach, createEvent, createStore, restore } from 'effector'
import { persist } from 'effector-storage/local'
import { ethers } from 'ethers'
import invariant from 'tiny-invariant'
import { desiredChain, desiredChainId, shortenAddress } from 'utils'
import { toDecimal } from 'utils/numbers'
import { ProviderId } from './wallets'

export type Wallet = {
  id: ProviderId
  state: 'connected' | 'notConnected' | 'connecting' | 'error'
} | null

export const logout = createEvent()
export const openWallet = createEvent()
export const closeWallet = createEvent()
export const setWallet = createEvent<Wallet>()
export const connectProvider = createEvent<ProviderId>()
export const setNetworkIsOk = createEvent<boolean>()
export const setAddress = createEvent<string>()
export const openAboutKyc = createEvent()
export const closeAboutKyc = createEvent()
export const setProvider = createEvent<ethers.providers.Web3Provider | null>()

export const $provider = restore(setProvider, null)
export const $targetTokenBalance = createStore(toDecimal(0))
export const $address = restore(setAddress, '')
export const $showWallet = createStore(false)
export const $showAboutKyc = createStore(false)
export const $networkIsOk = restore(setNetworkIsOk, false)
export const $shortAddress = $address.map(shortenAddress)

export const $wallet = restore(setWallet, null)
persist({ store: $wallet, key: 'wallet' })

export const switchNetworkFx = attach({
  source: $provider,
  async effect(provider) {
    await provider?.provider.request?.({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: desiredChainId }],
    })
  },
})

export const addNetworkFx = attach({
  source: $provider,
  async effect(provider) {
    await provider?.provider.request?.({
      method: 'wallet_addEthereumChain',
      params: [desiredChain],
    })
  },
})

export const fetchTargetTokenBalanceFx = attach({
  source: { address: $address, provider: $provider },
  async effect({ address, provider }) {
    invariant(provider, 'ethereum provider not found')

    const balance = await provider.getBalance(address)
    return toDecimal(balance._hex).div(1e18)

    // const abi = [
    //   'function balanceOf(address _owner) public view returns (uint256 balance)',
    // ]

    // const targetTokenAddress = '0x2F109021aFe75B949429fe30523Ee7C0D5B27207' // todo OCC
    // const targetTokenDecimal = 18
    // const contract = new ethers.Contract(targetTokenAddress, abi, provider)
    // const balance = await contract.balanceOf(address)
    // return new Decimal(balance?._hex ?? 0).div(
    //   new Decimal(10).pow(targetTokenDecimal)
    // )
  },
})

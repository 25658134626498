import detectEthereumProvider from '@metamask/detect-provider'
import { EthereumProvider } from '@walletconnect/ethereum-provider'

export async function getMetamaskProvider() {
  return await detectEthereumProvider()
}

export async function getWalletConnectProvider() {
  return await EthereumProvider.init({
    projectId:
      process.env.REACT_APP_PROJECT_ID ?? 'bab099279184b703122c88edad7fe06f',
    chains: [1313161554], //  1313161555 - testnet
    showQrModal: true,
    metadata: {
      name: 'NEARStarter',
      description: 'NEAR’s DAO-Governed Innovation Launchpad',
      url: 'https://app.nearstarter.fi/',
      icons: [
        'https://nearstarter.s3.eu-central-1.amazonaws.com/NearStarterLogoforIDOpage.png',
      ],
    },
  })
}

import { attach, createEffect, createEvent, createStore } from 'effector'
import { ethers } from 'ethers'
import { graphqlSdk } from 'gql/client'
import { $generalInfo } from 'models/generalInfo'
import { $session } from 'models/sessions'
import { $address, $provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { toDecimal } from 'utils/numbers'
import { poolAbi } from './abi'
import { Pool, PrivatePool, PublicPool } from './pools'

export const $pools = $generalInfo
  .map((data) => data?.pools.sort((p1, p2) => (p1.id > p2.id ? 1 : -1)) ?? [])
  .map((pools) =>
    pools.map((p) => (p.meta.private ? new PrivatePool(p) : new PublicPool(p)))
  )

export const $activePools = $pools /* $pools.map((pools) =>
  pools.filter((pool) =>!pool.finished && !pool.comingSoon)
)*/

export const $upcomingPools = $pools.map((pools) =>
  pools.filter((pool) => pool.comingSoon)
)

export const $finishedPools = $pools.map((pools) =>
  pools.filter((pool) => pool.finished)
)

export const buyFx = attach({
  source: [$address, $provider],
  async effect(
    [address, provider],
    { pool, rawAmount }: { pool: Pool; rawAmount: string }
  ) {
    invariant(provider, 'web3 provider not found')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const mlt = toDecimal(10).pow(pool.targetTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    const signer = provider.getSigner()
    const res = await signer.sendTransaction({
      from: address,
      to: pool.address,
      data: '0xcd3293de', // reserve
      value: amount,
    })

    return `${res?.hash ?? ''}`
  },
})
export const claimFx = attach({
  source: $provider,
  async effect(provider, pool: Pool) {
    invariant(provider, 'web3 provider not found')

    const signer = provider.getSigner()
    const poolContract = new ethers.Contract(pool.address, poolAbi, signer)
    const res = await poolContract.withdrawReservedTokens()
    return `${res?.hash ?? ''}`
  },
})

export const refundFx = attach({
  source: $provider,
  async effect(provider, pool: Pool) {
    invariant(provider, 'web3 provider not found')

    const signer = provider.getSigner()
    const poolContract = new ethers.Contract(pool.address, poolAbi, signer)
    const res = await poolContract.refund()
    return `${res?.hash ?? ''}`
  },
})

export type RegisterModalStatus =
  | 'open'
  | 'pending'
  | 'failed'
  | 'registered'
  | ''

export const setRegisterModalStatus = createEvent<RegisterModalStatus>()
export const $registerModalStatus = createStore<RegisterModalStatus>('')

export const registerFx = createEffect(async (pool: Pool) => {
  const session = $session.getState()

  const res = await graphqlSdk.RegisterPoolParticipation({
    input: {
      blockchain: 'aurora',
      poolAddress: pool.address,
      session,
    },
  })

  return res
})

import classNames from 'classnames'
import ProjectPageTerminal from 'components/collections/ProjectPageTerminalMaster'
import ProjectPageTierMaster from 'components/collections/ProjectPageTierMaster'
import DetailsInformation from 'components/dumb/DetailsInformation'
import ProjectPageHeading from 'components/dumb/ProjectPageHeading'
import ProjectPageSocialLinks from 'components/dumb/ProjectPageSocialLinks'
import ProjectPageTimeline from 'components/dumb/ProjectPageTimeline'
import ProjectPageVesting from 'components/dumb/ProjectPageVesting'
import SaleProgress from 'components/dumb/SaleProgress'
import SaleStatistics from 'components/dumb/SaleStatistics'
import ParticipationGuide from 'components/smart/SmartProjectPageParticipationGuide'
import { useStore } from 'effector-react'
import { setSelectedPoolAddress } from 'models/generalInfo'
import { $pools } from 'models/pools'
import { $session } from 'models/sessions'
import { $targetTokenBalance } from 'models/wallet'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tw } from 'utils'

export default function ProjectDetails() {
  const targetTokenBalance = useStore($targetTokenBalance)
  const pools = useStore($pools)
  const connected = useStore($session.map(Boolean))
  const terminalRef = useRef(null)
  const { id: selectedPoolAddress } = useParams()
  const navigate = useNavigate()

  const base = tw`my-[3.35rem] grid dxs:grid-cols-2 gap-y-[1.4rem] dxs:gap-[1.4rem]`
  const inProgress = tw`grid-cols-[1fr,1fr] dxs:grid-cols-[1fr,1fr,2fr]`

  const pool = pools.find((p) => p.address === selectedPoolAddress)
  setSelectedPoolAddress(selectedPoolAddress ?? '')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!pool) {
      const timer = setTimeout(() => {
        navigate('/')
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [pool, navigate])

  document.title = `NEARStarter | ${pool?.name}`

  return (
    <>
      {pool && (
        <div className="pt-[3rem] pb-[6rem]">
          <ProjectPageHeading {...pool.getHeadingProps()} />
          <div className={classNames(base, { [inProgress]: pool.inProgress })}>
            <SaleStatistics {...pool.getSaleStatisticsProps()} />
            <ProjectPageTierMaster {...pool.getTierProps(connected)} />
            <SaleProgress {...pool.getSaleProgressProps()} />
            <div
              className={`col-span-2 mt-[2rem] min-h-[18.5rem] ml:col-span-1 dxs:mt-0 ${
                pool.inProgress ? 'col-span-2 dxs:col-span-1' : ''
              }`}
              ref={terminalRef}
            >
              <ProjectPageTerminal
                {...pool.getTerminalProps(connected, targetTokenBalance)}
              />
            </div>
          </div>
          <ProjectPageTimeline {...pool.getTimelineProps()} />
          <ProjectPageSocialLinks {...pool.getSocialLinks()} />
          <ProjectPageVesting
            terminalRef={terminalRef}
            {...pool.getVesting()}
          />
          <ParticipationGuide
            terminalRef={terminalRef}
            {...pool.getParticipationGuideProps(connected)}
          />
          {pool.original.meta.markdown && (
            <DetailsInformation markdown={pool.original.meta.markdown} />
          )}
        </div>
      )}
    </>
  )
}

import ModalHeader from 'components/dumb/ModalHeader'
import { closeWallet, connectProvider } from 'models/wallet'
import { wallets } from 'models/wallet/wallets'
import t from 'utils/t'

export default function WalletList() {
  return (
    <div>
      <ModalHeader label="Wallet" onRightClick={closeWallet} />
      <div className="w-full">
        {wallets.map(({ id, name, icon }) => (
          <div
            key={id}
            className="flex flex-1 border border-black/25 hover:bg-graySecondary"
          >
            <button
              className="flex flex-1 items-center justify-between p-4"
              onClick={() => connectProvider(id)}
            >
              <p className="font-manrope font-light">{name}</p>
              <img src={icon} alt={name} className="h-[1rem]" />
            </button>
          </div>
        ))}
        <p className="pt-4 text-center font-manrope text-sm text-gray">
          {t('terms_of_use_text', {
            termsOfUse: (
              <a
                className="underline hover:no-underline"
                href="/pdf/nearstarter-terms-and-conditions.pdf"
                target="__blank"
              >
                {t('terms_of_use')}
              </a>
            ),
          })}
        </p>
      </div>
    </div>
  )
}

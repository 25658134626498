type Props = {
  name: string
  value: string
  valueClassName?: string
}

export default function DotLeaders({ name, value }: Props) {
  return (
    <div className="mb-[0.4rem] flex items-end justify-between last:mb-0">
      <div className="mr-[0.3rem] whitespace-nowrap text-[0.8rem]">{name}</div>
      <div className="relative bottom-[0.3rem] h-[0.1rem] w-full grow border-b border-dashed border-grayTertiary"></div>
      <div className="ml-[0.3rem] whitespace-nowrap text-[0.8rem] text-pink">
        {value}
      </div>
    </div>
  )
}

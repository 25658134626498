import { Disclosure } from '@headlessui/react'
import ModalHeader from 'components/dumb/ModalHeader'
import { useStore } from 'effector-react'
import { $kyc } from 'models/generalInfo'
import { closeAboutKyc, closeWallet } from 'models/wallet'
import { useState } from 'react'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'
import { openUrl } from 'utils'
import t from 'utils/t'

// todo: update text for near
const items = [
  {
    question: t('is_kyc_required'),
    answer: t('is_kyc_required_answer'),
  },
  {
    question: t('how_long'),
    answer: t('how_long_answer'),
  },
  {
    question: t('why_failed'),
    answer: t('why_failed_answer'),
  },
]

export default function AboutKyc() {
  const kyc = useStore($kyc)
  const [current, setCurrent] = useState(0)

  return (
    <div className="font-manrope">
      <ModalHeader
        label="KYC Verification"
        onLeftClick={closeAboutKyc}
        onRightClick={closeWallet}
      />
      <div className="space-y-4">
        {items.map(({ question, answer }, index) => {
          const open = current === index

          return (
            <Disclosure
              key={question}
              as="div"
              className="flex flex-1 flex-col items-start border border-black/25 p-4"
            >
              <>
                <Disclosure.Button
                  className="flex w-full items-center justify-between text-start"
                  onClick={() => setCurrent(index)}
                >
                  <div>{question}</div>
                  <ChevronIcon
                    className={`h-4 w-4 transform fill-current text-pink ${
                      open ? 'rotate-90' : '-rotate-90'
                    }`}
                  />
                </Disclosure.Button>
                {open && (
                  <Disclosure.Panel static className="mt-2 text-sm text-gray">
                    {answer}
                  </Disclosure.Panel>
                )}
              </>
            </Disclosure>
          )
        })}
        <div className="text-sm text-gray">
          {t('having_kyc_issues', {
            button: (
              <button
                className="cursor-pointer underline hover:no-underline"
                onClick={() => openUrl(kyc.supportLink)}
              >
                {t('contact_support')}
              </button>
            ),
          })}
        </div>
      </div>
    </div>
  )
}

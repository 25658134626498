import { ReactNode } from 'react'
import { ReactComponent as ConnectTierIcon } from 'resources/images/icons/tiers/connect.svg'
import { ReactComponent as NoTier } from 'resources/images/icons/tiers/no-tier.svg'
import { ReactComponent as Tier1 } from 'resources/images/icons/tiers/tier1.svg'
import { ReactComponent as Tier2 } from 'resources/images/icons/tiers/tier2.svg'
import { ReactComponent as Tier3 } from 'resources/images/icons/tiers/tier3.svg'
import { ReactComponent as Tier4 } from 'resources/images/icons/tiers/tier4.svg'
import { ReactComponent as Tier5 } from 'resources/images/icons/tiers/tier5.svg'

export const TIERS_MAP: {
  [key: string | number]: { image: string; name: string }
} = {
  1: { image: 'bg-tier1', name: 'Lunapunk' },
  2: { image: 'bg-tier3', name: 'Solarpunk' },
  3: { image: 'bg-tier4', name: 'Nanopunk' },
  4: { image: 'bg-tier2', name: 'Biopunk' },
  5: { image: 'bg-tier5', name: 'Cybernoir' },
}

export function getTierIconInIdo(
  level: number,
  connected: boolean,
  className?: string
): ReactNode {
  const connCn = 'absolute bottom-0 right-[-1rem] z-0 h-[26.45rem] w-[27.05rem]'
  const tierCn = className
    ? className
    : 'absolute right-[-4.3rem] bottom-[-1rem] h-[28.5rem] w-[28.5rem]'

  if (!connected) {
    return <ConnectTierIcon className={connCn} />
  }

  switch (level) {
    case 1:
      return <Tier1 className={tierCn} />
    case 2:
      return <Tier2 className={tierCn} />
    case 3:
      return <Tier3 className={tierCn} />
    case 4:
      return <Tier4 className={tierCn} />
    case 5:
      return <Tier5 className={tierCn} />
    case 0:
      return <NoTier className={tierCn} />
    default:
      return <ConnectTierIcon className={connCn} />
  }
}

import TextLink from 'components/dumb/TextLink'
import { useStore } from 'effector-react'
import { $staking } from 'models/staking'
import { closeWallet } from 'models/wallet'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'
import { tw } from 'utils'
import t from 'utils/t'
import { getTierIconInIdo } from 'utils/tiers'

export default function WalletTier() {
  const navigate = useNavigate()
  const { stakingSymbol, stakingBalance, level, tierName } = useStore($staking)
  const tierImage = getTierIconInIdo(level, true, tw`w-24 h-24`)
  const balance = numeral(stakingBalance).format('0,[.][0000]')

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="flex flex-col">
            <div className="font-manrope font-light text-grayTertiary">
              {t('your_tier')}
            </div>
            <div className="font-pressStart2P">{tierName}</div>
          </div>
          <div className="mt-1">
            <div className="font-manrope font-light text-grayTertiary">
              {t('your_stake')}
            </div>
            <div className="font-pressStart2P">
              {balance} {stakingSymbol}
            </div>
          </div>
        </div>
        <div>{tierImage}</div>
      </div>
      <div className="mt-4 flex items-center space-x-4">
        <TextLink
          onClick={() => {
            navigate('/staking')
            closeWallet()
          }}
        >
          {t('go_to_staking')}
        </TextLink>
      </div>
    </div>
  )
}

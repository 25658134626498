import { sample, split } from 'effector'
import { persist } from 'effector-storage/local'
import { ethers } from 'ethers'
import { desiredChainId } from 'utils'
import { getMetamaskProvider, getWalletConnectProvider } from 'utils/providers'
import {
  $address,
  $networkIsOk,
  $showAboutKyc,
  $showWallet,
  $targetTokenBalance,
  $wallet,
  Wallet,
  addNetworkFx,
  closeAboutKyc,
  closeWallet,
  connectProvider,
  fetchTargetTokenBalanceFx,
  logout,
  openAboutKyc,
  openWallet,
  setAddress,
  setNetworkIsOk,
  setProvider,
  setWallet,
  switchNetworkFx,
} from '.'
import { ProviderId, metamask, walletConnect, wallets } from './wallets'

// persist({ store: $wallet, key: 'wallet' })
persist({ store: $networkIsOk, key: 'networkIsOk' })
persist({ store: $address, key: 'address' })

sample({
  clock: openWallet,
  fn: () => true,
  target: $showWallet,
})

sample({
  clock: openWallet,
  source: { $networkIsOk, $wallet },
  filter: ({ $networkIsOk, $wallet }) => {
    return !$networkIsOk && !!$wallet && $wallet.state === 'connected'
  },
  target: switchNetworkFx,
})

sample({
  clock: closeWallet,
  fn: () => false,
  target: [$showWallet, $showAboutKyc],
})

sample({
  clock: switchNetworkFx.fail,
  target: addNetworkFx,
})

sample({
  clock: addNetworkFx.done,
  target: switchNetworkFx,
})

sample({
  clock: logout,
  target: closeWallet,
})

$wallet.watch(async (wallet) => {
  if (wallet?.state !== 'connected') return

  let provider: ethers.providers.Web3Provider | null = null

  if (wallet.id === ProviderId.MetaMask) {
    const metamaskProvider = await getMetamaskProvider()
    provider = metamaskProvider
      ? new ethers.providers.Web3Provider(metamaskProvider, 'any')
      : null
    setProvider(provider)
  }

  if (wallet.id === ProviderId.WalletConnect) {
    const walletConnectProvider = await getWalletConnectProvider()
    provider = new ethers.providers.Web3Provider(walletConnectProvider, 'any')
    setProvider(provider)
  }

  provider?.on('network', (currentChain) => {
    const ok = currentChain?.chainId === Number(desiredChainId)
    setNetworkIsOk(ok)
  })

  provider?.on('accountsChanged', (accounts) => {
    if (Array.isArray(accounts) && accounts.length > 0) {
      setAddress(accounts[0])
    }
  })
})

$wallet.reset(logout)

split({
  source: connectProvider,
  match: {
    metamask: (id) => id === ProviderId.MetaMask,
    walletConnect: (id) => id === ProviderId.WalletConnect,
  },
  cases: {
    metamask: metamask.authFx,
    walletConnect: walletConnect.authFx,
  },
})

wallets.forEach(({ id, authFx }) => {
  sample({
    clock: authFx.doneData,
    fn: (auth) => auth.address,
    target: setAddress,
  })

  sample({
    clock: authFx,
    fn: (): Wallet => ({ id, state: 'connecting' }),
    target: setWallet,
  })

  sample({
    clock: authFx.doneData,
    fn: (): Wallet => ({ id, state: 'connected' }),
    target: setWallet,
  })

  sample({
    clock: authFx.fail,
    fn: (): Wallet => ({ id, state: 'error' }),
    target: setWallet,
  })
})

sample({
  clock: openAboutKyc,
  fn: () => true,
  target: $showAboutKyc,
})

sample({
  clock: closeAboutKyc,
  fn: () => false,
  target: $showAboutKyc,
})

sample({
  clock: fetchTargetTokenBalanceFx.doneData,
  target: $targetTokenBalance,
})

// При старте приложения пробуем загрузить данные
setTimeout(fetchTargetTokenBalanceFx, 250)

// Отправляем запросы каждые 5 секунд
setInterval(fetchTargetTokenBalanceFx, 5000)

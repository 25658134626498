import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'
import { ReactComponent as CloseIcon } from 'resources/images/icons/close.svg'

interface Props {
  label: string
  onRightClick(): void
  onLeftClick?(): void
}

export default function ModalHeader({
  label,
  onLeftClick,
  onRightClick,
}: Props) {
  return (
    <div className="flex items-center justify-between pb-5">
      {onLeftClick && (
        <ChevronIcon
          className="h-4 w-4 cursor-pointer fill-current text-pink"
          onClick={onLeftClick}
        />
      )}
      <p className="font-manrope text-lg font-light text-blackSecondary">
        {label}
      </p>
      <CloseIcon
        className="h-4 w-4 cursor-pointer fill-current text-pink"
        onClick={onRightClick}
      />
    </div>
  )
}

import { createEffect, createStore } from 'effector'
import { IndexTokenInfo } from 'gql'
import { graphqlSdk } from 'gql/client'

export const fetchIndexPoolInfoFx = createEffect(async () => {
  return await graphqlSdk.IndexPoolInfo()
})

export const $indexPoolInfo = createStore<IndexTokenInfo | null>(null)

export const $assets = $indexPoolInfo.map(
  (indexPoolInfo) => indexPoolInfo?.assets
)

$indexPoolInfo
  .on(fetchIndexPoolInfoFx.doneData, (_, data) => data.indexTokenInfo)
  .reset(fetchIndexPoolInfoFx.fail)

import Field from 'components/dumb/Field'
import Timer from 'components/dumb/Timer'
import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import isEmpty from 'lodash/isEmpty'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as JoinSaleArrow } from 'resources/images/icons/join-sale-arrow.svg'
import useCountdownTimeAndTitle from 'utils/pool/useCountdownTimeAndTitle'
import t from 'utils/t'

export interface HomeActivePoolCardProps {
  background: string
  dueDiligenceReport: string
  idoTokenSymbol: string
  inProgress: boolean
  incubated: boolean
  initialMarketCapUSD: string
  label: string
  logo: string
  name: string
  pipeline: PoolPipeline
  poolStatus: PoolStatus
  subtitle: string
  tags: string[]
  targetTokenSymbol: string
  tokenPrice: string
  totalTarget: string
  tokensToSell: string
  address: string
  vesting?: Vesting
}

export default function HomeActivePoolCard({
  background,
  dueDiligenceReport,
  idoTokenSymbol,
  inProgress,
  incubated,
  initialMarketCapUSD,
  label,
  logo,
  name,
  pipeline,
  poolStatus,
  subtitle,
  targetTokenSymbol,
  tokenPrice,
  totalTarget,
  tokensToSell,
  vesting,
  address,
  onClick,
}: HomeActivePoolCardProps & { onClick: (address: string) => void }) {
  const { countdownTime, countdownTitle } = useCountdownTimeAndTitle({
    vesting,
    pipeline,
    poolStatus,
  })

  return (
    <div onClick={() => onClick(address)} className="group relative z-20">
      <div className="relative z-30 cursor-pointer border border-gray9A bg-grayDarkSecondary transition-colors group-hover:border-grayDA">
        <div className="grid grid-cols-1 grid-rows-[1fr,2fr] ml:auto-rows-fr t:grid-rows-1 dxs:grid-cols-2">
          <div className="relative flex flex-col">
            <div
              className="absolute z-0 h-full w-full bg-cover bg-105 bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${background})` }}
            ></div>
            <div className="relative z-10 flex grow flex-col justify-between p-[1rem]">
              <div className="flex h-[1.4rem] w-[3.3rem] items-center justify-between bg-bubbleGum px-[0.5rem]">
                <div className="relative top-[0.02rem] h-[0.4rem] w-[0.4rem]">
                  <div className="absolute h-full w-full animate-ping-slow rounded-full bg-grayLight opacity-70"></div>
                  <div className="relative h-[0.4rem] w-[0.4rem] rounded-full bg-grayLight"></div>
                </div>
                <span className="text-[0.7rem] font-medium uppercase text-grayLight">
                  {t('live')}
                </span>
              </div>
              <div className="flex">
                <ReactTooltip
                  className="react-tooltip-custom"
                  id={`private-public-tooltip-${address}`}
                />
                <ReactTooltip
                  className="react-tooltip-custom"
                  id={`incubated-tooltip-${address}`}
                />
                <ReactTooltip
                  className="react-tooltip-custom"
                  id={`diligence-tooltip-${address}`}
                />
                <Label className="mr-[0.6rem]">
                  <div
                    data-tip={t('ido_type_tooltip_text')}
                    data-for={`private-public-tooltip-${address}`}
                  >
                    {label}
                  </div>
                </Label>
                {incubated && (
                  <Label className="mr-[0.6rem]">
                    <div
                      className="flex w-[1.2rem] items-center justify-center"
                      data-tip={t('incubated_by_launchpad')}
                      data-for={`incubated-tooltip-${address}`}
                    >
                      <div className="relative top-[-0.1rem] h-[1.2rem] w-[1.2rem] bg-aquariumSmall bg-contain bg-no-repeat" />
                    </div>
                  </Label>
                )}
                {!isEmpty(dueDiligenceReport) && (
                  <Label>
                    <div
                      className="w-[1rem]"
                      data-tip={t('due_diligence_report')}
                      data-for={`diligence-tooltip-${address}`}
                    >
                      <div className="relative top-[-0.1rem] h-[1.2rem] w-[1.2rem] bg-fishSmall bg-contain bg-no-repeat" />
                    </div>
                  </Label>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-[1rem] p-[1rem] pb-[0.5rem] dxs:h-[18.8rem] ds:h-[18rem]">
            <div className="grid grid-cols-[2rem,auto] grid-rows-[2rem,auto] gap-y-[0.2rem] gap-x-[0.8rem] ml:grid-cols-[4.6rem,auto] ml:grid-rows-3 ml:gap-y-0">
              <div className="row-span-3 ">
                <div className="bg-black p-[0.4rem] ">
                  <div
                    className="h-[1.2rem] w-[1.2rem] bg-cover bg-center bg-no-repeat ml:h-[3.8rem] ml:w-[3.8rem]"
                    style={{ backgroundImage: `url(${logo})` }}
                  />
                </div>
              </div>
              <div className="self-center font-pressStart2P text-[0.6rem] leading-[150%] text-grayLight ml:self-start ml:text-[0.8rem]">
                {name}
              </div>
              <p className="col-span-full truncate font-manrope text-[0.7rem] text-gray ml:col-auto">
                {subtitle}
              </p>
              <div className="col-span-full font-manrope text-[0.7rem] text-pink ml:col-auto">
                {idoTokenSymbol}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-[1rem] ml:grid-cols-2">
              <Field
                icon="target"
                title={t('target')}
                value={totalTarget}
                symbol={targetTokenSymbol}
              />
              <Field
                icon="usd"
                title={t('token_price')}
                value={tokenPrice}
                symbol={targetTokenSymbol}
              />
              <Field
                icon="usd"
                title={t('initial_market_cap')}
                value={initialMarketCapUSD}
                symbol="USD"
                // symbol={targetTokenSymbol}
                breakLine
              />
              <Field
                icon="token"
                title={t('tokens_to_sell')}
                value={tokensToSell}
                symbol={idoTokenSymbol}
                breakLine
              />
            </div>
            <div>
              <Timer endTime={countdownTime} text={countdownTitle} />
            </div>
          </div>
        </div>
      </div>
      {inProgress && (
        <div className="relative z-20 m-auto w-[calc(100%-2rem)] cursor-pointer overflow-hidden">
          <div className="peer relative z-10 flex items-center justify-center py-[0.8rem]">
            <span className="flex items-center font-manrope text-[0.7rem] font-semibold uppercase">
              {t('join_sale_button')}
              <JoinSaleArrow className="ml-[0.7rem] h-[0.7rem] w-[0.7rem]" />
            </span>
          </div>
          <div className="home-page-greenGradient absolute left-0 top-0 z-0 h-full w-[110%] translate-x-[-3%] transition-transform peer-hover:translate-x-[0%]"></div>
        </div>
      )}
    </div>
  )
}

function Label({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <span
      className={`${className} relative border border-gray9A bg-blackSecondary py-[0.25rem] px-[0.5rem] pb-[0.05rem] font-codersCrux text-[1.2rem] uppercase leading-none text-greenLight`}
    >
      {children}
    </span>
  )
}

import { useNavigate } from 'react-router-dom'
import { noop } from 'utils'

interface Props {
  children: React.ReactNode
  href?: string
  onClick?(): void
  className?: string
}

export default function TextLink({
  href,
  children,
  className = '',
  onClick = noop,
}: Props) {
  const navigate = useNavigate()
  const isInternal = href && href.startsWith('/')

  const handleClick = () => {
    if (isInternal) {
      navigate(href)
    } else {
      if (href) {
        window.open(href, '_blank', 'noopener,noreferrer')
      } else if (onClick) {
        onClick()
      }
    }
  }

  return (
    <div
      className={`
      ${className}
      inline cursor-pointer
      font-manrope leading-[140%]
      text-pink underline decoration-pink underline-offset-[0.25rem]
      hover:text-pinkHover hover:decoration-pinkHover active:text-pinkActive active:decoration-pinkActive
      `}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

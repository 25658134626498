import { createEffect } from 'effector'
import { ethers } from 'ethers'
import { graphqlSdk } from 'gql/client'
import { isMobile } from 'react-device-detect'
import metamaskIcon from 'resources/images/wallets/metamask.svg'
import walletConnectIcon from 'resources/images/wallets/walletconnect.svg'
import invariant from 'tiny-invariant'
import { getMetamaskProvider, getWalletConnectProvider } from 'utils/providers'

export enum ProviderId {
  MetaMask = 'metaMask',
  WalletConnect = 'walletConnect',
}

function openMetaMaskUrl(url: string) {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const metamaskAuth = async () => {
  const metamaskProvider = await getMetamaskProvider()
  const provider = metamaskProvider
    ? new ethers.providers.Web3Provider(metamaskProvider, 'any')
    : null

  if (isMobile && !provider?.provider.isMetaMask) {
    // window.open(process.env.REACT_APP_DAPP_URL, '_blank')

    openMetaMaskUrl(process.env.REACT_APP_DAPP_URL!.toString())
  }

  if (!provider?.provider.isMetaMask) {
    window.open('https://metamask.io', '_blank', 'noopener,noreferrer')
  }

  invariant(provider?.provider.isMetaMask, 'metamask: extension not found')

  const accounts = await window.ethereum?.request({
    method: 'eth_requestAccounts',
  })
  invariant(
    Array.isArray(accounts) && accounts.length,
    'metamask: accounts not found'
  )

  const address = accounts[0] as string
  const authMessageData = await graphqlSdk.GetAuthMessage({
    blockchain: 'aurora',
    address,
  })
  const authMessage = authMessageData?.getAuthMessage ?? ''

  let sign
  if (provider?.provider.request) {
    sign = await provider?.provider.request({
      method: 'personal_sign',
      params: [address, authMessage],
    })
  }
  const authData = await graphqlSdk.Auth({
    input: { blockchain: 'aurora', sign, authMessage },
  })

  return { address, session: authData.auth.session }
}

export const walletConnectAuth = async () => {
  const walletConnectProvider = await getWalletConnectProvider()
  await walletConnectProvider.connect()
  const provider = new ethers.providers.Web3Provider(
    walletConnectProvider,
    'any'
  )

  const address = walletConnectProvider.accounts[0]
  const authMessageData = await graphqlSdk.GetAuthMessage({
    blockchain: 'aurora',
    address,
  })
  const authMessage = authMessageData?.getAuthMessage ?? ''

  let sign = ''
  if (provider.provider.request) {
    sign = await provider.provider.request({
      method: 'personal_sign',
      params: [address, authMessage],
    })
  }

  const authData = await graphqlSdk.Auth({
    input: { blockchain: 'aurora', sign, authMessage },
  })

  return { address, session: authData.auth.session }
}

export const metamask = {
  id: ProviderId.MetaMask,
  name: 'MetaMask',
  icon: metamaskIcon,
  authFx: createEffect(metamaskAuth),
}

export const walletConnect = {
  id: ProviderId.WalletConnect,
  name: 'Wallet Connect',
  icon: walletConnectIcon,
  authFx: createEffect(walletConnectAuth),
}

export const wallets = [metamask, walletConnect]

export const getWalletNameById = (id: ProviderId) =>
  wallets.filter((w) => w.id === id).pop()?.name ?? ''

export const getWalletIconById = (id: ProviderId) =>
  wallets.filter((w) => w.id === id).pop()?.icon ?? ''

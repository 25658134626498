import classNames from 'classnames'

interface Props {
  title: string
  children: React.ReactNode
  className?: string
}

export default function WindowsContainer({
  children,
  title,
  className = '',
}: Props) {
  return (
    <div className="relative flex flex-col border border-gray9A drop-shadow-blockShadow">
      <div className="flex w-full justify-between bg-grayLight py-[0.4rem] px-[0.6rem]">
        <div className="font-manrope text-[0.7rem] leading-[140%] text-blackSecondary">
          {title}
        </div>
        <div className="relative right-[-0.4rem] w-[3.5rem] bg-stakingWindowsButtons bg-contain bg-no-repeat" />
      </div>
      <div
        className={classNames(
          className,
          `relative flex grow flex-col bg-blackSecondary p-[1rem]`
        )}
      >
        {children}
      </div>
    </div>
  )
}

export function TripleShadowText({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) {
  const baseFont = 'text-[1.8rem]'
  return (
    <div
      className={classNames(
        className,
        { [baseFont]: !className },
        'flex flex-col space-y-[-0.9rem] font-pressStart2P'
      )}
    >
      <div className="outline-title relative z-10 leading-[1.2] text-black opacity-10">
        {children}
      </div>
      <div className="outline-title relative z-10 leading-[1.2] text-black opacity-30">
        {children}
      </div>
      <div className="relative z-20 leading-[1.2] text-grayLight">
        {children}
      </div>
    </div>
  )
}

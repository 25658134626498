import React from 'react'
import { ReactComponent as MediumIcon } from 'resources/images/icons/social/medium.svg'
import { ReactComponent as TwitterIcon } from 'resources/images/icons/social/twitter.svg'
import { ReactComponent as TelegramIcon } from 'resources/images/icons/social/telegram.svg'
import { ReactComponent as GithubIcon } from 'resources/images/icons/social/github.svg'
import { ReactComponent as DiscordIcon } from 'resources/images/icons/social/discord.svg'
import { ReactComponent as WhitepaperIcon } from 'resources/images/icons/social/whitepaper.svg'

export type SocialLinkIconType =
  | 'telegram'
  | 'medium'
  | 'twitter'
  | 'github'
  | 'discord'
  | 'whitepaper'
  | string

export type SocialLinkProps = {
  url?: string | null
  // name?: string
  icon?: SocialLinkIconType
  className?: string
  fillClassName?: string
}

export default function SocialLink({
  url,
  // name,
  icon = 'telegram',
  className,
}: SocialLinkProps) {
  const iconComp = React.useMemo(() => {
    switch (icon) {
      case 'telegram':
        return <TelegramIcon />
      case 'medium':
        return <MediumIcon />
      case 'twitter':
        return <TwitterIcon />
      case 'github':
        return <GithubIcon />
      case 'discord':
        return <DiscordIcon />
      case 'whitepaper':
        return <WhitepaperIcon />
    }
  }, [icon])

  if (!url) {
    return null
  }
  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div
      onClick={handleClick}
      className={`group cursor-pointer bg-pink bg-opacity-15 fill-current p-[0.35rem] transition-colors hover:bg-opacity-30 ${className}`}
    >
      {iconComp
        ? React.cloneElement(iconComp, {
            className: 'fill-current w-[0.8rem] h-[0.8rem]',
          })
        : null}
    </div>
  )
}

import { StakingTier } from 'gql'
import { toDecimal } from 'utils/numbers'
import TextLink from 'components/dumb/TextLink'
import { ReactComponent as CrossIcon } from 'resources/images/staking/cross.svg'
import { ReactComponent as CrossIcon2 } from 'resources/images/staking/cross2.svg'
import { ReactComponent as PinIcon } from 'resources/images/staking/pin.svg'
import t from 'utils/t'

interface TiersProps {
  tiers: StakingTier[]
  stakingSymbol: string
}
export default function Tiers({ tiers, stakingSymbol }: TiersProps) {
  const aboutTiersLink =
    'https://medium.com/near-starter/nearstarter-dao-tiers-how-much-nstart-should-you-own-and-stake-53b7137aaa82'

  return (
    <div className="relative z-20">
      <div className="mb-[1.2rem] flex justify-between">
        <h2 className="font-manrope text-[1.2rem] font-light leading-[1.2] text-white">
          {t('tiers')}
        </h2>
        <TextLink href={aboutTiersLink} className="text-[0.7rem]">
          {t('about_tiers')}
        </TextLink>
      </div>
      <div className="no-scrollbar flex overflow-y-hidden overflow-x-scroll">
        <div className="flex grow items-center justify-between space-x-[2rem] dxs:space-x-0">
          {tiers.map((tier) => (
            <TierCard
              key={tier.level}
              level={tier.level}
              name={tier.name}
              poolWeightMultiplier={tier.allocationMultiple}
              participationTickets={tier.participationTickets}
              img={TIERS_MAP[tier.level].img}
              shape={TIERS_MAP[tier.level].shape}
              squares={TIERS_MAP[tier.level].squares}
              accentColor={TIERS_MAP[tier.level].accentColor}
              minStake={tier.minStake}
              stakingSymbol={stakingSymbol}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function TierCard({
  level,
  img,
  shape,
  name,
  squares,
  accentColor,
  poolWeightMultiplier,
  participationTickets,
  minStake,
  stakingSymbol,
}: TierConfig & {
  poolWeightMultiplier: string
  name: string
  participationTickets: string
  minStake: string
  stakingSymbol: string
}) {
  const isTop = level === 1 || level === 2
  const top = {
    width: `
    w-[229px] 
    dxs:w-[190px] 
    ds:w-[224px] 
    `,
    height: `
    h-[295px] 
    dxs:h-[245px] 
    ds:h-[287px] 
    `,
  }
  const normal = {
    width: `
    w-[215px] 
    dxs:w-[178.6px] 
    ds:w-[209px] 
    `,
    height: `
    h-[295px]
    dxs:h-[245px] 
    ds:h-[288px] 
    `,
  }

  const cn = isTop ? top : normal
  const oddsMultiple = toDecimal(participationTickets).eq(0)
    ? 'MAX'
    : `+${participationTickets}`

  const crossOneCn = 'absolute h-[0.65rem] w-[0.65rem]'
  const crossTwoCn = 'absolute h-[1rem] w-[1rem]'

  return (
    <div className={`relative flex overflow-hidden ${cn.width} ${cn.height}`}>
      <div
        className={`absolute h-full w-full ${shape} bg-cover bg-no-repeat`}
      ></div>
      <div
        className={`relative z-20 flex grow flex-col ${
          isTop ? 'left-[0.2rem] px-[2.0rem]' : 'px-[1.5rem]'
        } pt-[1rem] pb-[0.6rem]`}
      >
        {level === 5 && (
          <>
            <CrossIcon
              className={`top-[4.05rem] left-[1.4rem] ${crossOneCn}`}
            />
            <CrossIcon2 className={`top-[6rem] right-[0.9rem] ${crossTwoCn}`} />
          </>
        )}
        {level === 4 && (
          <>
            <CrossIcon
              className={`top-[4.05rem] left-[1.4rem] ${crossOneCn}`}
            />
            <CrossIcon2 className={`top-[5rem] right-[0.9rem] ${crossTwoCn}`} />
          </>
        )}
        {level === 3 && (
          <>
            <CrossIcon className={`top-[4.6rem] left-[1.4rem] ${crossOneCn}`} />
            <CrossIcon2
              className={`top-[4.7rem] right-[0.9rem] ${crossTwoCn}`}
            />
          </>
        )}
        {level === 2 && (
          <>
            <CrossIcon className={`top-[5.2rem] left-[1.7rem] ${crossOneCn}`} />
            <CrossIcon2
              className={`top-[4.5rem] right-[1.3rem] ${crossTwoCn}`}
            />
          </>
        )}
        {level === 1 && (
          <>
            <CrossIcon className={`top-[6.2rem] left-[1.7rem] ${crossOneCn}`} />
            <CrossIcon2
              className={`top-[2.5rem] right-[1.3rem] ${crossTwoCn}`}
            />
          </>
        )}
        {(level === 1 || level === 2) && (
          <PinIcon className="absolute top-[1rem] right-[1.4rem] h-[1.2rem] w-[1.2rem]" />
        )}
        <div className="whitespace-nowrap text-center font-codersCrux text-[2.35rem] leading-[55%] tracking-[-0.03em] text-blackSecondary/80">
          TIER <span className={accentColor}>{ROMAN_NUMERALS_MAP[level]}</span>
        </div>
        <div className="whitespace-nowrap text-center font-codersCrux text-[0.8rem] uppercase leading-[90%] tracking-[-0.03em]  text-grayDark/35 ">
          {name}
        </div>
        <div className="relative">
          <div
            className={`
            m-auto
            h-[5.05rem] w-[5.05rem]
            bg-cover bg-no-repeat
            ${img}
            ${level === 5 ? '' : ''}
            ${level === 4 ? '' : ''}
            ${level === 2 ? '' : ''}
          `}
          />
        </div>
        <div className="text-center">
          <div
            className={`mt-[0.3rem] whitespace-nowrap font-codersCrux text-[1.65rem] uppercase leading-[50%] text-grayDark
            ${level === 2 ? 'opacity-80' : ''}
            ${level === 3 ? 'opacity-60' : ''}
            ${level === 4 ? 'opacity-60' : ''}
            ${level === 5 ? 'opacity-40' : ''}
            `}
          >
            {minStake} {stakingSymbol}
          </div>
          <div className="font-manrope text-[0.45rem] uppercase text-grayDark opacity-35">
            {stakingSymbol} Stake required
          </div>
        </div>
        <div className="mt-[0.1rem] flex justify-between">
          {squares.map((square, index) => (
            <div
              key={index}
              className={`h-[0.22rem] w-[0.22rem] 
                ${square === 0 ? 'bg-blackSecondary/10' : ''}
                ${square === 1 ? 'bg-grayLightSecondary' : ''}
                ${square === 2 ? 'bg-greenLight' : ''}
                ${square === 3 ? 'bg-purple' : ''}
                `}
            />
          ))}
        </div>
        <div className="mt-[0.191rem] mb-[0.1rem] h-[0.05rem] w-full bg-black/20 dxs:mt-[0.4rem] ds:mt-[0.1rem]" />
        <div className="mt-[0.1rem] flex items-center justify-between dxs:mt-[0.4rem] ds:mt-[0.1rem]">
          <div className="font-manrope text-[0.5rem] leading-[1.2] tracking-[-0.03rem] text-grayDark">
            {t('participation')} <br /> {t('odds_multiplier')}
          </div>
          <div
            className={`
              relative left-[0.2rem] font-codersCrux text-[2rem] leading-[0.7] tracking-[-0.03em] text-grayDark
              ${level === 2 ? 'opacity-80' : ''}
              ${level === 3 ? 'opacity-60' : ''}
              ${level === 4 ? 'opacity-60' : ''}
              ${level === 5 ? 'opacity-40' : ''}
            `}
          >
            {oddsMultiple}
          </div>
        </div>
        <div className="mt-[0] flex items-center justify-between">
          <div className="font-manrope text-[0.5rem] leading-[1.2] tracking-[-0.03rem] text-grayDark">
            {t('pool_weight')} <br /> {t('multiplier')}
          </div>
          <div
            className={`flex font-codersCrux text-[2rem] leading-[0.7] ${accentColor}`}
          >
            <span>X</span>
            <span className="tracking-[-0.12em]">{poolWeightMultiplier}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const ROMAN_NUMERALS_MAP: { [key: string]: string } = {
  0: '-',
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
  5: 'V',
}

interface TierConfig {
  level: number
  img: string
  shape: string
  squares: number[]
  accentColor: string
}

const TIERS_MAP: Record<number, TierConfig> = {
  5: {
    level: 5,
    img: 'bg-tierCyber',
    shape: 'bg-tierShape1',
    squares: [2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    accentColor: 'text-greenLight',
  },
  4: {
    level: 4,
    img: 'bg-tierOcto',
    shape: 'bg-tierShape1',
    squares: [2, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    accentColor: 'text-greenLight',
  },
  3: {
    level: 3,
    img: 'bg-tierTurtle',
    shape: 'bg-tierShape1',
    squares: [2, 2, 2, 2, 2, 3, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    accentColor: 'text-purple',
  },
  2: {
    level: 2,
    img: 'bg-tierShark',
    shape: 'bg-tierShape2',
    squares: [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 0, 0, 0, 0, 0],
    accentColor: 'text-purple',
  },
  1: {
    level: 1,
    img: 'bg-tierWhale',
    shape: 'bg-tierShape2',
    squares: [2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 0],
    accentColor: 'text-purple',
  },
  0: {
    level: 0,
    img: '',
    shape: '',
    squares: [],
    accentColor: 'text-purple',
  },
}

import classNames from 'classnames'
import { ReactNode } from 'react'
import { noop, tw } from 'utils'

interface Props {
  className?: string
  children?: ReactNode
  onClick?(): void
  disabled?: boolean
  type?: 'connect' | 'secondary' | 'green' | 'switch'
}

export default function Button({
  children,
  className,
  onClick = noop,
  type = 'connect',
  disabled = false,
}: Props) {
  const base = classNames(
    className,
    tw`border-[0.05rem] whitespace-nowrap py-[0.4rem] px-[0.8rem] text-[0.8rem] leading-[140%] uppercase disabled:bg-graySecondary disabled:text-gray disabled:border-gray9A`
  )
  const connect = tw`contour-shadow border-grayLight bg-pink hover:bg-pinkHover active:bg-pinkActive`
  const networkSwitch = tw`contour-shadow border-grayTertiary bg-bubbleGum hover:bg-pinkHover active:bg-pinkActive`
  const secondary = tw`border-blackSecondary border-opacity-60 text-opacity-60 hover:border-opacity-40 active:bg-blackSecondary active:bg-opacity-40`
  const green = tw`contour-shadow border-grayLight bg-greenLight hover:bg-greenLightHover active:bg-greenLightActive`

  return (
    <button
      className={classNames(base, {
        [connect]: type === 'connect',
        [secondary]: type === 'secondary',
        [green]: type === 'green',
        [networkSwitch]: type === 'switch',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

import Button from 'components/dumb/Button'
import CurrencyInputPanel from 'components/dumb/CurrencyInputPanel'
import { useStore } from 'effector-react'
import { $staking, removeStakeFx } from 'models/staking'
import { openWallet } from 'models/wallet'
import numeral from 'numeral'
import { useState } from 'react'
import { noop } from 'utils'
import t from 'utils/t'

export default function UnstakeTerminal() {
  const staking = useStore($staking)
  const [input, setInput] = useState('')

  const handleInput = (value: string) => {
    setInput(value)
  }

  const onMax = () => {
    setInput(staking.stakingBalance)
  }

  let buttonText = 'Unstake'
  let buttonDisabled = false
  let handleClick = noop
  let inputDisabled = false
  switch (staking.unstakeTerminalState(input)) {
    case 'walletNotConnected':
      buttonText = t('connect_wallet')
      handleClick = openWallet
      inputDisabled = true
      break
    case 'readyToUnstake':
      handleClick = () => removeStakeFx(input).then(() => setInput(''))
      break
    case 'waitingForInput':
      buttonText = t('enter_an_amount')
      break
    case 'insufficientBalance':
      buttonText = t('insufficient_balance')
      buttonDisabled = true
      break
    case 'notReadyToUnstake':
    default:
      buttonDisabled = true
      break
  }

  return (
    <div>
      <CurrencyInputPanel
        label={t('you_unstake')}
        leftSub={t('current_stake')}
        rightSub={t('wallet')}
        leftCount={numeral(staking.stakingBalanceDec).format('0.00')}
        rightCount={numeral(staking.walletBalanceDec).format('0.00')}
        symbol={staking.stakingSymbol}
        value={input}
        onUserInput={handleInput}
        onMax={onMax}
        decimals={staking.stakingTokenDecimals}
        showMaxButton={!inputDisabled}
        disabled={inputDisabled}
      />
      <Button
        className="w-full"
        disabled={buttonDisabled}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </div>
  )
}

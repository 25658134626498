import { useStore } from 'effector-react'
import { $activePools, $finishedPools, $upcomingPools } from 'models/pools'
import ActivePoolCard from 'components/dumb/HomeActivePoolCard'
import UpcomingPoolCard from 'components/dumb/HomeUpcomingPoolCard'
import SmartHomeStats from './SmartHomeStats'
import classNames from 'classnames'
import HomeFinishedPoolsTable from 'components/dumb/HomeFinishedPoolsTable'
import { useNavigate } from 'react-router-dom'
import t from 'utils/t'

export default function SmartHome() {
  const activePools = useStore($activePools)
  const upcomingPools = useStore($upcomingPools)
  const finishedPools = useStore($finishedPools)
  const navigate = useNavigate()

  document.title =
    'NEARStarter | New generation launchpad for NEAR | Incubated by FMFW.io'

  const handleNavigatePool = (id: string) => {
    navigate(`/pool/${id}`)
  }

  return (
    <div className="mt-[2.4rem] mb-[10rem] dxs:mt-[6.4rem]">
      <p className="mb-[0.4rem] font-manrope text-[0.9rem] leading-[1.5] text-grayLight">
        {t('welcom_to')}
      </p>
      <div className="font-pressStart2P text-[1.3rem] leading-[1.2] text-white ml:text-[1.55rem] dxs:text-[2rem]">
        <h1 className="relative mb-[0.2rem] flex items-center">
          <span className="outline-title absolute left-0 z-[-1] ml-[0.3rem] hidden translate-x-[-102.3%] font-pressStart2P text-[2.53rem] leading-[1.2] text-black opacity-10 dxs:block">
            {t('launchpad_name')}
          </span>
          <span className="text-pink">NEAR</span>STARTER
          <span className="outline-title z-[-1] ml-[0.3rem] hidden font-pressStart2P text-[2.53rem] leading-[1.2] text-black opacity-10 dxs:block">
            {t('launchpad_name')}
          </span>
        </h1>
        <h1 className="outline-title uppercase text-black">{t('launchpad')}</h1>
      </div>
      <p className="mt-[0.4rem] mb-[12rem] w-full font-manrope  text-[0.7rem] leading-[140%] text-grayTertiary ml:mb-[5rem] ml:w-[70%] dxs:w-[52%]">
        {t('header_text')}
      </p>
      <div className="grid auto-rows-[9.75rem] grid-cols-1 gap-[1rem] ml:grid-cols-2 dxs:grid-cols-3 dxs:grid-rows-[11.65rem]">
        <SmartHomeStats />
      </div>
      <div className="mt-[4.3rem]">
        {activePools.length > 0 ? (
          <>
            <Heading>{t('active_projects')}</Heading>
            <div className="flex flex-col space-y-[1rem]">
              {activePools.map((pool) => (
                <ActivePoolCard
                  key={`pool-card-${pool.address}`}
                  {...pool.getHomeActiveCardProps()}
                  onClick={handleNavigatePool}
                />
              ))}
            </div>
          </>
        ) : null}

        {false && upcomingPools.length > 0 ? (
          <div className="relative mt-[4rem]">
            <div className="absolute left-1/2 -top-[19rem] z-[-1] h-[25rem] w-screen -translate-x-1/2 bg-grayLight" />
            <div className="mb-[1rem] flex items-center space-x-[1rem]">
              <Heading className="!text-blackSecondary">
                {t('upcoming_projects')}
              </Heading>
              <div className="relative h-[1.1rem] w-full">
                <div className="home-page-upcomingGradient absolute z-[-1] h-full w-screen" />
              </div>
            </div>
            <div className="mb-[4rem] grid grid-cols-1 gap-[1rem] dxs:grid-cols-2 ">
              {upcomingPools.map((pool) => (
                <UpcomingPoolCard
                  {...pool.getHomeUpcomingCardProps()}
                  onClick={handleNavigatePool}
                />
              ))}
            </div>
          </div>
        ) : null}

        {false && finishedPools.length > 0 ? (
          <>
            <Heading>{t('finished_projects')}</Heading>
            <HomeFinishedPoolsTable
              finishedPools={finishedPools}
              onClick={handleNavigatePool}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

function Heading({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={classNames(
        className,
        'mb-[1rem] whitespace-nowrap font-manrope text-[1.6rem] font-bold leading-[120%] text-grayLight antialiased'
      )}
    >
      {children}
    </div>
  )
}

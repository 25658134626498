import { ReactComponent as LeftIcon } from 'resources/images/icons/left.svg'
import { ReactComponent as MineIcon } from 'resources/images/icons/mine.svg'
import { ReactComponent as StakedIcon } from 'resources/images/icons/staked.svg'
import { ReactComponent as TargetIcon } from 'resources/images/icons/target.svg'
import { ReactComponent as TokenIcon } from 'resources/images/icons/tokens-to-sell.svg'
import { ReactComponent as UniqueStackersIcon } from 'resources/images/icons/unique-stackers.svg'
import { ReactComponent as USDIcon } from 'resources/images/icons/usd.svg'
import { ReactComponent as UserIcon } from 'resources/images/icons/user.svg'
import { ReactComponent as VestingIcon } from 'resources/images/icons/vesting.svg'

export interface Props {
  title: string
  value?: string
  icon?:
    | 'usd'
    | 'user'
    | 'token'
    | 'mine'
    | 'left'
    | 'vesting'
    | 'target'
    | 'staked'
    | 'unique-stackers'
  symbol?: string
  sub?: string
  valueClassName?: string
  symbolClassName?: string
  breakLine?: boolean
}

export default function Field({
  icon,
  title,
  value,
  symbol = undefined,
  sub = undefined,
  valueClassName = '',
  symbolClassName = '',
  breakLine = false,
}: Props) {
  return (
    <div className="flex items-start">
      <FieldIcon icon={icon} />
      <div>
        <div className="mb-[0.15rem] font-manrope text-[0.7rem] leading-[120%] text-grayTertiary">
          {title}
        </div>
        <div
          className={`
          flex items-baseline font-pressStart2P text-grayLight 
          ${
            breakLine
              ? 'flex-row space-x-[0.2rem] dxs:flex-col dxs:space-x-0 ds:flex-row ds:space-x-[0.2rem]'
              : 'space-x-[0.2rem]'
          }`}
        >
          <span className={`text-[0.8rem] leading-[150%] ${valueClassName}`}>
            {value === '0' ? 'TBA' : value}
          </span>
          {symbol && (
            <span
              className={`relative top-[-0.058rem] text-[0.5rem] uppercase ${symbolClassName}`}
            >
              {value === '0' ? null : symbol}
            </span>
          )}
        </div>
        {sub && (
          <div className="font-pressStart2P text-[0.5rem] leading-[150%] text-grayTertiary">
            {sub}
          </div>
        )}
      </div>
    </div>
  )
}

function FieldIcon({ icon }: Pick<Props, 'icon'>) {
  const className = 'mr-[0.5rem] flex h-[2rem] w-[2rem]'
  switch (icon) {
    case 'target':
      return <TargetIcon className={className} />
    case 'usd':
      return <USDIcon className={className} />
    case 'token':
      return <TokenIcon className={className} />
    case 'staked':
      return <StakedIcon className={className} />
    case 'user':
      return <UserIcon className={className} />
    case 'mine':
      return <MineIcon className={className} />
    case 'left':
      return <LeftIcon className={className} />
    case 'unique-stackers':
      return <UniqueStackersIcon className={className} />
    case 'vesting':
      return <VestingIcon className={className} />
    default:
      return null
  }
}

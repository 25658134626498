import Field from 'components/dumb/Field'
import t from 'utils/t'

export interface Props {
  state: 'private' | 'public'
  totalTarget: string
  tokensToSell: string
  tokenPrice: string
  targetTokenSymbol: string
  idoTokenSymbol: string
  initialMarketCapUSD: string
  participantsCount: string
  registeredCount: string
  vestingUnlock?: string
}

export default function ProjectPageFieldsMaster(props: Props) {
  switch (props.state) {
    case 'private':
      return <PrivatePoolFields {...props} />
    case 'public':
      return <PublicPoolFields {...props} />
    default:
      return null
  }
}

function CommonFields(props: Props) {
  return (
    <>
      <Field
        icon="target"
        title={t('target')}
        value={props.totalTarget}
        symbol={props.targetTokenSymbol}
      />
      <Field
        icon="usd"
        title={t('token_price')}
        value={props.tokenPrice}
        symbol={props.targetTokenSymbol}
      />
      <Field
        icon="usd"
        title={t('initial_market_cap')}
        value={props.initialMarketCapUSD}
        symbol="USD"
        // symbol={props.targetTokenSymbol}
        breakLine
      />
      <Field
        icon="token"
        title={t('tokens_to_sell')}
        value={props.tokensToSell}
        symbol={props.idoTokenSymbol}
        breakLine
      />
    </>
  )
}

function PublicPoolFields(props: Props) {
  return (
    <>
      <CommonFields {...props} />
      <Field title={t('vesting')} icon="staked" value={props.vestingUnlock} />
      <Field
        icon="user"
        title={t('user_participated')}
        value={props.participantsCount}
      />
    </>
  )
}

function PrivatePoolFields(props: Props) {
  return (
    <>
      <CommonFields {...props} />
      <Field
        icon="user"
        title={t('user_registered')}
        value={props.registeredCount}
      />
      <Field
        icon="user"
        title={t('user_participated')}
        value={props.participantsCount}
      />
    </>
  )
}

import { PoolPipeline, PoolStatus, Vesting } from 'gql'
import t from 'utils/t'

interface Options {
  poolStatus: PoolStatus
  pipeline: PoolPipeline
  vesting?: Vesting
  isPrivate?: boolean
}
export default function useCountdownTimeAndTitle({
  poolStatus,
  pipeline,
  vesting,
  isPrivate,
}: Options) {
  let countdownTime
  let countdownTitle

  switch (poolStatus) {
    case PoolStatus.ComingSoon:
      countdownTime = isPrivate ? pipeline.registrationTime : pipeline.startTime
      countdownTitle = isPrivate
        ? pipeline.registrationTime
          ? t('registartion_opens_in')
          : t('registration_dates_to_be_announced')
        : t('ido_begins_in')
      break
    case PoolStatus.Registration:
      countdownTime = pipeline.prelaunchTime
      countdownTitle = t('registration_ends_in')
      break
    case PoolStatus.Prelaunch:
      countdownTime = pipeline.startTime
      countdownTitle = t('ido_begins_in')
      break
    case PoolStatus.InProgress:
      countdownTime = pipeline.finishTime
      countdownTitle = t('sale_ends_in')
      break
    case PoolStatus.SoldOut:
    case PoolStatus.SuccessfullyFinished:
      const dateIndex = vesting?.currentPeriodIndex ?? -1
      const unlockCheckpoints = vesting?.unlockCheckpoints || []
      const unlockDate =
        unlockCheckpoints.length > dateIndex + 1
          ? unlockCheckpoints[dateIndex + 1]
          : ''

      countdownTime = unlockDate
      countdownTitle = unlockDate !== '' ? t('next_unlock_in') : null
      break
    default:
      break
  }
  return { countdownTime, countdownTitle }
}

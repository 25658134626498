import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'
import { noop, tw } from 'utils'

type Props = {
  className?: string
  children: ReactNode
  items: {
    text: string
    onClick?(): void
    disabled?: boolean
  }[]
  disabled?: boolean
}

export default function Dropdown({ children, items, className = '' }: Props) {
  const base = tw`border py-[0.4rem] px-[0.8rem] cursor-pointer uppercase relative`
  const connect = tw`border-graySecondary bg-pink hover:bg-pinkHover active:bg-pinkActive`

  return (
    <div className={`${className} relative font-manrope`}>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                base,
                connect,
                `flex w-full items-center justify-between space-x-[0.6rem] ${
                  open ? 'relative z-50 bg-pinkHover' : ''
                }`
              )}
            >
              <div className="whitespace-nowrap text-[0.8rem]">{children}</div>
              <div className="flex items-center space-x-[0.6rem]">
                <div className="h-[1.15rem] w-[0.05rem] bg-gray" />
                <ChevronIcon
                  className={`h-[0.6rem] w-[0.6rem] fill-current text-blackSecondary ${
                    open ? 'rotate-90' : '-rotate-90'
                  } transform cursor-pointer`}
                />
              </div>
            </Menu.Button>
            {items.length > 0 ? (
              <Menu.Items className="absolute z-10 w-full border border-gray9A bg-graySecondary">
                {items.map(({ text, onClick = noop, disabled = false }) => (
                  <Menu.Item
                    key={text}
                    className={`cursor-pointer p-[0.6rem] text-[0.7rem] hover:bg-pink hover:bg-opacity-20 ${
                      disabled ? 'text-grayTertiary' : 'text-blackSecondary'
                    }`}
                    as="div"
                    onClick={onClick}
                  >
                    {text}
                  </Menu.Item>
                ))}
              </Menu.Items>
            ) : null}
          </>
        )}
      </Menu>
    </div>
  )
}

import classNames from 'classnames'
import { ReactNode } from 'react'
import { noop } from 'utils'

interface GhostButtonProps {
  onClick?(): void
  children?: ReactNode
  className?: string
  selected?: boolean
}
export default function GhostButton({
  children,
  onClick = noop,
  className = '',
  selected = false,
}: GhostButtonProps) {
  const base = classNames(
    className,
    `cursor-pointer rounded-[0.5rem] border pt-[0.1rem]
     px-[0.3rem] font-codersCrux text-[1.2rem] leading-none  outline-none 
     hover:border-pinkHover hover:text-pinkHover
     active:border-pinkActive active:text-pinkActive`
  )
  const normal = 'bg-pink/15 text-pink border-pink '
  const sel = `bg-pink border-gray9A text-blackSecondary border-gray9A`

  return (
    <button
      onClick={onClick}
      className={classNames(base, {
        [sel]: selected,
        [normal]: !selected,
      })}
    >
      {children}
    </button>
  )
}

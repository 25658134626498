import { ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: ReactNode
  onClose(): void
  show: boolean
}

export default function Modal({ children, onClose, show }: Props) {
  if (!portal || !show) {
    return null
  }

  return createPortal(
    <>
      <div className="fixed top-1/2 left-1/2 z-[60] box-border h-auto w-[25rem] max-w-full -translate-x-1/2 -translate-y-1/2 transform bg-grayLight p-5">
        {children}
      </div>
      <div
        className="fixed z-50 h-screen w-screen bg-black bg-opacity-70"
        onClick={onClose}
      />
    </>,
    portal
  )
}

const portal = document.getElementById('modal')
